import React, { ComponentProps, useState } from "react"
import { Flex, Text, classNames } from "@opensea/ui-kit"
import { uniqBy } from "lodash"
import styled from "styled-components"
import { useDebounce } from "use-debounce"
import { VerificationIcon } from "@/components/collections/VerificationIcon.react"
import { Overflow } from "@/components/common/Overflow"
import { Panel, PanelProps } from "@/components/layout/Panel"
import {
  FilterBackground,
  FilterDivContainer,
} from "@/components/search/FilterBackground.react"
import { FilterSearchInput } from "@/components/search/FilterSearchInput.react"
import { SearchOptions } from "@/components/search/SearchOptions.react"
import { Block } from "@/design-system/Block"
import { Image } from "@/design-system/Image"
import { useNoSuspenseLazyLoadQuery } from "@/hooks/useNoSuspenseLazyLoadQuery"
import { useTranslate } from "@/hooks/useTranslate"
import {
  CollectionFilterLazyQuery,
  CollectionFilterLazyQuery$variables,
  CollectionSort,
} from "@/lib/graphql/__generated__/CollectionFilterLazyQuery.graphql"
import { getNodes, graphql } from "@/lib/graphql/graphql"
import { shortSymbolDisplay } from "@/lib/helpers/numberUtils"

const PAGE_SIZE = 20
const DEBOUNCE_DEFAULT = 250

type Props = {
  filters: CollectionFilterLazyQuery$variables
  selectedSlugs: ReadonlyArray<string>
  setSlugs: (slugs: ReadonlyArray<string> | undefined) => unknown
  sortBy?: CollectionSort
  scopeQueryByAssetOwner?: boolean
  mode?: PanelProps["mode"]
}

export const CollectionFilter = ({
  filters,
  mode = "start-closed",
  selectedSlugs,
  setSlugs,
  sortBy,
  scopeQueryByAssetOwner = true,
}: Props) => {
  const t = useTranslate("components")
  const [query, setQuery] = useState("")
  const [debouncedQuery] = useDebounce(query, DEBOUNCE_DEFAULT)

  const [collectionData] =
    useNoSuspenseLazyLoadQuery<CollectionFilterLazyQuery>(
      graphql`
        query CollectionFilterLazyQuery(
          $assetCreator: IdentityInputType
          $assetOwner: IdentityInputType
          $categories: [CollectionSlug!]
          $chains: [ChainScalar!]
          $collections: [CollectionSlug!]
          $count: Int
          $includeHidden: Boolean = false
          $onlyPrivateAssets: Boolean = false
          $query: String
          $sortBy: CollectionSort = SEVEN_DAY_VOLUME
        ) {
          collections(
            assetOwner: $assetOwner
            assetCreator: $assetCreator
            onlyPrivateAssets: $onlyPrivateAssets
            chains: $chains
            first: $count
            includeHidden: $includeHidden
            parents: $categories
            query: $query
            sortBy: $sortBy
          ) {
            edges {
              node {
                assetCount
                imageUrl
                name
                slug
                isVerified
              }
            }
          }
          selectedCollections: collections(
            first: 25
            collections: $collections
            includeHidden: true
          ) {
            edges {
              node {
                assetCount
                imageUrl
                name
                slug
                isVerified
              }
            }
          }
        }
      `,
      {
        count: PAGE_SIZE,
        query: debouncedQuery,
        sortBy,
        ...filters,
        // We want users to be able to search for any collection if they manually type something in, but the default list can be the ones they own
        assetOwner:
          debouncedQuery && !scopeQueryByAssetOwner
            ? undefined
            : filters.assetOwner,
        // TODO (@marketplace): Investigate why this query returns really weird results when collections is null
        collections: filters.collections ?? [],
      },
      { skip: query.length <= 2 },
    )

  const collections = collectionData?.collections
  const selectedCollections = collectionData?.selectedCollections

  const uniqueCollections = uniqBy(
    [...getNodes(selectedCollections), ...getNodes(collections)],
    c => c.slug,
  )
  const sortedCollections = [
    ...uniqueCollections.filter(c => selectedSlugs.includes(c.slug)),
    ...uniqueCollections.filter(c => !selectedSlugs.includes(c.slug)),
  ]

  const items: ComponentProps<typeof SearchOptions>["items"] =
    sortedCollections.map(collection => {
      const isSelected = selectedSlugs.includes(collection.slug)
      return {
        id: collection.slug,
        isSelected,
        label: (
          <Flex className="w-[90%] items-center">
            <Flex
              className={classNames(
                "mr-4 min-w-[32px] overflow-hidden rounded-lg bg-component-gray-1",
                Boolean(collection.imageUrl) && "border border-level-2",
              )}
            >
              {collection.imageUrl ? (
                <Image
                  alt={collection.name}
                  height={32}
                  src={collection.imageUrl}
                  width={32}
                />
              ) : (
                <Block height="32px" width="32px" />
              )}
            </Flex>
            <Flex className="mr-3 min-w-0 items-center">
              <CollectionNameContainer>
                <Text asChild>
                  <div>
                    <Overflow>{collection.name}</Overflow>
                  </div>
                </Text>
              </CollectionNameContainer>
              {collection.isVerified && (
                <VerificationIcon
                  showTooltip={false}
                  size="tiny"
                  verificationStatus="VERIFIED"
                />
              )}
            </Flex>
            {!!collection.assetCount && (
              <Text
                asChild
                className="ml-auto mr-3"
                color="secondary"
                data-testid="asset-count"
                size="small"
              >
                <div>
                  {shortSymbolDisplay(collection.assetCount, {
                    threshold: 1_000_000_000,
                    formatDisplay: true,
                  })}
                </div>
              </Text>
            )}
          </Flex>
        ),
        onChange: () => {
          // When an item is clicked, either remove it or add it to the selected collections.
          const newCollections = isSelected
            ? selectedSlugs.filter(c => c !== collection.slug)
            : [...selectedSlugs, collection.slug]
          setSlugs(newCollections.length ? newCollections : undefined)
        },
        collection,
      }
    })

  return (
    <FilterDivContainer>
      <Panel
        bodyClassName="FilterBackground--body"
        headerClassName="FilterBackground--header"
        id="filter-collection"
        isHeaderPadded={false}
        mode={mode}
        title={t("collectionFilter.title", "Collections")}
        unmountChildrenOnClose
      >
        <FilterBackground>
          <Block marginY="8px" padding="0 8px">
            <FilterSearchInput
              placeholder={t("collectionFilter.search", "Search")}
              value={query}
              onChange={e => setQuery(e.target.value)}
            />
          </Block>
          <SearchOptions
            items={items}
            name="collection-filter"
            type="checkbox"
          />
        </FilterBackground>
      </Panel>
    </FilterDivContainer>
  )
}

const CollectionNameContainer = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`
