/**
 * @generated SignedSource<<94d6d4b6d1c458db2b231abfb0201a97>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SafelistRequestStatus = "APPROVED" | "DISABLED_TOP_TRENDING" | "NOT_REQUESTED" | "REQUESTED" | "VERIFIED" | "%future added value";
export type IdentityInputType = {
  address?: string | null;
  name?: string | null;
  username?: string | null;
};
export type ProfileCollectionFilterItemsLazyQuery$variables = {
  assetOwner: IdentityInputType;
  collections: ReadonlyArray<string>;
  count: number;
  cursor?: string | null;
  onlyPrivateAssets: boolean;
  query?: string | null;
  safelistRequestStatuses?: ReadonlyArray<SafelistRequestStatus> | null;
};
export type ProfileCollectionFilterItemsLazyQuery$data = {
  readonly collectionsLimited: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly slug: string;
        readonly " $fragmentSpreads": FragmentRefs<"ProfileCollectionFilterItem_collection">;
      } | null;
    } | null>;
  };
  readonly selectedCollections: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly slug: string;
        readonly " $fragmentSpreads": FragmentRefs<"ProfileCollectionFilterItem_collection">;
      } | null;
    } | null>;
  };
};
export type ProfileCollectionFilterItemsLazyQuery = {
  response: ProfileCollectionFilterItemsLazyQuery$data;
  variables: ProfileCollectionFilterItemsLazyQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "assetOwner"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "collections"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "count"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cursor"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "onlyPrivateAssets"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "query"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "safelistRequestStatuses"
},
v7 = {
  "kind": "Variable",
  "name": "assetOwner",
  "variableName": "assetOwner"
},
v8 = {
  "kind": "Literal",
  "name": "includeHidden",
  "value": false
},
v9 = {
  "kind": "Variable",
  "name": "onlyPrivateAssets",
  "variableName": "onlyPrivateAssets"
},
v10 = {
  "kind": "Variable",
  "name": "query",
  "variableName": "query"
},
v11 = {
  "kind": "Variable",
  "name": "safelistRequestStatuses",
  "variableName": "safelistRequestStatuses"
},
v12 = {
  "kind": "Literal",
  "name": "sortBy",
  "value": "EXPECTED_VALUE"
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v14 = {
  "args": [
    {
      "kind": "Variable",
      "name": "owner",
      "variableName": "assetOwner"
    }
  ],
  "kind": "FragmentSpread",
  "name": "ProfileCollectionFilterItem_collection"
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endCursor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v18 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "count"
},
v19 = [
  {
    "kind": "Variable",
    "name": "collections",
    "variableName": "collections"
  },
  (v18/*: any*/),
  (v8/*: any*/),
  (v11/*: any*/),
  (v12/*: any*/)
],
v20 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  (v7/*: any*/),
  (v18/*: any*/),
  (v8/*: any*/),
  (v9/*: any*/),
  (v10/*: any*/),
  (v11/*: any*/),
  (v12/*: any*/)
],
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "logo",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isVerified",
  "storageKey": null
},
v24 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "unit",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "symbol",
    "storageKey": null
  }
],
v25 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "identity",
      "variableName": "assetOwner"
    }
  ],
  "concreteType": "CollectionAssetOwnerType",
  "kind": "LinkedField",
  "name": "collectionAssetOwner",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "listedQuantity",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ownedQuantity",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PriceType",
      "kind": "LinkedField",
      "name": "expectedValue",
      "plural": false,
      "selections": (v24/*: any*/),
      "storageKey": null
    }
  ],
  "storageKey": null
},
v26 = {
  "alias": null,
  "args": null,
  "concreteType": "CollectionStatsV2Type",
  "kind": "LinkedField",
  "name": "statsV2",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "PriceType",
      "kind": "LinkedField",
      "name": "floorPrice",
      "plural": false,
      "selections": (v24/*: any*/),
      "storageKey": null
    }
  ],
  "storageKey": null
},
v27 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ProfileCollectionFilterItemsLazyQuery",
    "selections": [
      {
        "alias": "collectionsLimited",
        "args": [
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/)
        ],
        "concreteType": "CollectionTypeConnection",
        "kind": "LinkedField",
        "name": "__ProfileCollectionFilter_collectionsLimited_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CollectionTypeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CollectionType",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/)
                ],
                "storageKey": null
              },
              (v16/*: any*/)
            ],
            "storageKey": null
          },
          (v17/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": "selectedCollections",
        "args": (v19/*: any*/),
        "concreteType": "CollectionTypeConnection",
        "kind": "LinkedField",
        "name": "collectionsLimited",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CollectionTypeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CollectionType",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v13/*: any*/),
                  (v14/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v3/*: any*/),
      (v6/*: any*/)
    ],
    "kind": "Operation",
    "name": "ProfileCollectionFilterItemsLazyQuery",
    "selections": [
      {
        "alias": null,
        "args": (v20/*: any*/),
        "concreteType": "CollectionTypeConnection",
        "kind": "LinkedField",
        "name": "collectionsLimited",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CollectionTypeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CollectionType",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v13/*: any*/),
                  (v21/*: any*/),
                  (v22/*: any*/),
                  (v23/*: any*/),
                  (v25/*: any*/),
                  (v26/*: any*/),
                  (v27/*: any*/),
                  (v15/*: any*/)
                ],
                "storageKey": null
              },
              (v16/*: any*/)
            ],
            "storageKey": null
          },
          (v17/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v20/*: any*/),
        "filters": [
          "assetOwner",
          "query",
          "sortBy",
          "onlyPrivateAssets",
          "includeHidden",
          "safelistRequestStatuses"
        ],
        "handle": "connection",
        "key": "ProfileCollectionFilter_collectionsLimited",
        "kind": "LinkedHandle",
        "name": "collectionsLimited"
      },
      {
        "alias": "selectedCollections",
        "args": (v19/*: any*/),
        "concreteType": "CollectionTypeConnection",
        "kind": "LinkedField",
        "name": "collectionsLimited",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CollectionTypeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CollectionType",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v13/*: any*/),
                  (v21/*: any*/),
                  (v22/*: any*/),
                  (v23/*: any*/),
                  (v25/*: any*/),
                  (v26/*: any*/),
                  (v27/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7be5d8d4c1e13d967404f769f4ceb738",
    "id": null,
    "metadata": {
      "connection": [
        {
          "count": "count",
          "cursor": "cursor",
          "direction": "forward",
          "path": [
            "collectionsLimited"
          ]
        }
      ]
    },
    "name": "ProfileCollectionFilterItemsLazyQuery",
    "operationKind": "query",
    "text": "query ProfileCollectionFilterItemsLazyQuery(\n  $assetOwner: IdentityInputType!\n  $collections: [CollectionSlug!]!\n  $count: Int!\n  $onlyPrivateAssets: Boolean!\n  $query: String\n  $cursor: String\n  $safelistRequestStatuses: [SafelistRequestStatus!]\n) {\n  collectionsLimited(assetOwner: $assetOwner, after: $cursor, first: $count, query: $query, sortBy: EXPECTED_VALUE, onlyPrivateAssets: $onlyPrivateAssets, includeHidden: false, safelistRequestStatuses: $safelistRequestStatuses) {\n    edges {\n      node {\n        slug\n        ...ProfileCollectionFilterItem_collection_2c1f9y\n        id\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n  selectedCollections: collectionsLimited(first: $count, collections: $collections, includeHidden: false, sortBy: EXPECTED_VALUE, safelistRequestStatuses: $safelistRequestStatuses) {\n    edges {\n      node {\n        slug\n        ...ProfileCollectionFilterItem_collection_2c1f9y\n        id\n      }\n    }\n  }\n}\n\nfragment ProfileCollectionFilterItem_collection_2c1f9y on CollectionType {\n  logo\n  name\n  slug\n  isVerified\n  collectionAssetOwner(identity: $assetOwner) {\n    listedQuantity\n    ownedQuantity\n    expectedValue {\n      unit\n      symbol\n    }\n  }\n  statsV2 {\n    floorPrice {\n      unit\n      symbol\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "efed5204201457bfbbaac9be7fbdb7c4";

export default node;
