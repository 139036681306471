import React from "react"
import { Text, Switch, SpaceBetween, FlexColumn, Flex } from "@opensea/ui-kit"
import { graphql, useFragment } from "react-relay"
import { Panel } from "@/components/layout/Panel"
import {
  FilterBackground,
  FilterDivContainer,
} from "@/components/search/FilterBackground.react"
import { Block } from "@/design-system/Block"
import { CreatorFeeSupportIcon } from "@/features/creator-fees/components/CreatorFeeSupportIcon.react"
import { useTranslate } from "@/hooks/useTranslate"
import { useTrackingFn } from "@/lib/analytics/hooks/useTrackingFn"
import { ListingsSupportCreatorFilter_collection$key } from "@/lib/graphql/__generated__/ListingsSupportCreatorFilter_collection.graphql"
import { basisPointsToPercentage, display } from "@/lib/helpers/numberUtils"
import { SearchDivider } from "./SearchDivider.react"

type Props = {
  collection: ListingsSupportCreatorFilter_collection$key
  supportsCreator: boolean
  setSupportsCreator: (supportsCreator: boolean) => unknown
}

export const ListingsSupportCreatorFilter = ({
  collection: collectionKey,
  setSupportsCreator,
  supportsCreator,
}: Props) => {
  const t = useTranslate("components")
  const collection = useFragment(
    graphql`
      fragment ListingsSupportCreatorFilter_collection on CollectionType {
        totalCreatorFeeBasisPoints
      }
    `,
    collectionKey,
  )
  const trackCheckListingSupportsCreatorToggle = useTrackingFn(
    "check listing supports creator toggle",
  )
  const trackUncheckListingSupportsCreatorToggle = useTrackingFn(
    "uncheck listing supports creator toggle",
  )

  return (
    <FilterDivContainer>
      <Panel
        bodyClassName="FilterBackground--body"
        headerClassName="FilterBackground--header"
        id="listings-support-creator-filter"
        isHeaderPadded={false}
        mode="start-open"
        title={t("listingSupportsCreatorFilter.title", "Creator earnings")}
        unmountChildrenOnClose
      >
        <FilterBackground>
          <SpaceBetween className="gap-5 px-2.5 py-3">
            <Flex className="gap-5">
              <Block>
                <CreatorFeeSupportIcon />
              </Block>
              <FlexColumn>
                <Block marginBottom="2px">
                  <Text.Body>
                    {t(
                      "listingSupportsCreatorFilter.label",
                      "Support creators",
                    )}
                  </Text.Body>
                </Block>
                <Text.Body className="text-secondary" size="tiny">
                  {t(
                    "listingSupportsCreatorFilter.tip",
                    "Show listings which pay the creator their requested earnings of {{creatorFeePercentage}}%.",
                    {
                      creatorFeePercentage: display(
                        basisPointsToPercentage(
                          collection.totalCreatorFeeBasisPoints,
                        ),
                      ),
                    },
                  )}
                </Text.Body>
              </FlexColumn>
            </Flex>
            <Block>
              <Switch
                checked={supportsCreator}
                onCheckedChange={checked => {
                  setSupportsCreator(checked)

                  if (checked) {
                    trackCheckListingSupportsCreatorToggle()
                  } else {
                    trackUncheckListingSupportsCreatorToggle()
                  }
                }}
              />
            </Block>
          </SpaceBetween>
          <SearchDivider />
        </FilterBackground>
      </Panel>
    </FilterDivContainer>
  )
}
