import React from "react"
import { Text } from "@opensea/ui-kit"
import { Panel } from "@/components/layout/Panel"
import {
  FilterBackground,
  FilterDivContainer,
} from "@/components/search/FilterBackground.react"
import { SearchOptions } from "@/components/search/SearchOptions.react"
import { useConnectedAddress } from "@/containers/WalletProvider/WalletProvider.react"
import { useTranslate } from "@/hooks/useTranslate"
import { IdentityInputType } from "@/lib/graphql/__generated__/DefaultAccountPageQuery.graphql"
import { addressesEqual } from "@/lib/helpers/address"

type OwnerFilterProps = {
  owner?: IdentityInputType
  setOwner: (owner?: IdentityInputType) => unknown
}

export const OwnerFilter = ({ owner, setOwner }: OwnerFilterProps) => {
  const t = useTranslate("components")
  const connectedAddress = useConnectedAddress()
  const items = [
    {
      label: (
        <Text.Body asChild className="text-primary" size="medium">
          <div>{t("ownerFilter.all", "All")}</div>
        </Text.Body>
      ),
      id: "all",
      isSelected: !addressesEqual(owner?.address, connectedAddress),
      onChange: () => setOwner(undefined),
    },
    {
      label: (
        <Text.Body asChild className="text-primary" size="medium">
          <div>{t("ownerFilter.me", "Me")}</div>
        </Text.Body>
      ),
      id: "me",
      isSelected: addressesEqual(owner?.address, connectedAddress),
      onChange: () =>
        setOwner(connectedAddress ? { address: connectedAddress } : undefined),
    },
  ]

  return (
    <FilterDivContainer>
      <Panel
        bodyClassName="FilterBackground--body"
        headerClassName="FilterBackground--header"
        id="filter-owner"
        isHeaderPadded={false}
        mode="start-closed"
        title={t("ownerFilter.ownerTitle", "Owner")}
        unmountChildrenOnClose
      >
        <FilterBackground>
          <SearchOptions items={items} name="filter-owner" type="radio" />
        </FilterBackground>
      </Panel>
    </FilterDivContainer>
  )
}
