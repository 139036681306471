import React from "react"
import {
  Icon,
  Breakpoint,
  Media,
  useIsLessThanSm,
  Text,
  Flex,
} from "@opensea/ui-kit"
import { isFunction, noop } from "lodash"
import styled, { css } from "styled-components"
import { BOTTOM_ACTION_BAR_HEIGHT_PX } from "@/components/common/BottomActionBar/BottomActionBar.react"
import {
  MOBILE_DRAWER_TRANSITION_DURATION,
  MOBILE_DRAWER_TRANSITION_TIMING_FUNCTION,
} from "@/components/layout/AccountOrCollectionPage/constants"
import { useAccountOrCollectionPageContext } from "@/components/layout/AccountOrCollectionPage/hooks/useAccountOrCollectionPageContext"
import { useSidebarContext } from "@/components/search/SidebarContext.react"
import { Z_INDEX } from "@/constants/zIndex"
import { Block } from "@/design-system/Block"
import { Button } from "@/design-system/Button"
import { Drawer } from "@/design-system/Drawer"
import { IconButton } from "@/design-system/IconButton"
import { useAnnouncementBanner } from "@/features/announcement-banner/utils/context"
import { useTranslate } from "@/hooks/useTranslate"
import { trackCloseSidebarFilter } from "@/lib/analytics/events/searchEvents"

type SearchFilterVariant = "default" | "profile"

export type SearchFilterProps = {
  children: ((close: () => unknown) => React.ReactNode) | React.ReactNode
  fullscreenBreakpoint?: Breakpoint
  clear: () => unknown
  onToggle?: (isOpen: boolean) => unknown
  topOffset?: number
  variant?: SearchFilterVariant
}

const SearchFilter = ({
  children,
  fullscreenBreakpoint = "sm",
  clear,
  onToggle,
  topOffset,
  variant = "default",
}: SearchFilterProps) => {
  const { visibleAnnouncementBannerHeight } = useAnnouncementBanner()
  const { setSidebarCollapsed } = useSidebarContext()
  const t = useTranslate("phoenix")
  const {
    sidebarOpen,
    contentTopOffset,
    isMobileFilterDrawerOpen,
    setIsMobileFilterDrawerOpen,
    isFooterShown,
  } = useAccountOrCollectionPageContext()
  const isMobile = useIsLessThanSm()

  const closeFilterSidebar = () => {
    setSidebarCollapsed(true)
    onToggle?.(true)
    trackCloseSidebarFilter()
  }

  const closeIfMobile = () => {
    if (isMobile) {
      closeFilterSidebar()
    }
  }

  return (
    <>
      <Media lessThan={fullscreenBreakpoint}>
        <CustomDrawer
          anchorSide="bottom"
          bannerHeight={visibleAnnouncementBannerHeight}
          isOpen={isMobileFilterDrawerOpen}
          isVisible
          style={{ zIndex: Z_INDEX.NAVBAR }}
          transitionDuration={MOBILE_DRAWER_TRANSITION_DURATION}
          transitionTimingFunction={MOBILE_DRAWER_TRANSITION_TIMING_FUNCTION}
          onClickAway={noop}
        >
          <Flex className="flex-col p-4">
            <Flex asChild className="relative mb-4 items-center justify-center">
              <header>
                <Text.Heading asChild size="small">
                  <h4>{t("search.filters", "Filters")}</h4>
                </Text.Heading>
                <Flex className="absolute right-0">
                  <IconButton
                    onClick={() => setIsMobileFilterDrawerOpen(false)}
                  >
                    <Icon size={24} value="close" />
                  </IconButton>
                </Flex>
              </header>
            </Flex>
            {isFunction(children) ? children(closeIfMobile) : children}
          </Flex>
          <Flex className="sticky bottom-0 z-[1] mt-auto w-full gap-2 border-t border-t-level-2 bg-elevation-2 p-4 dark:bg-elevation-1">
            <Button
              className="w-full"
              variant="secondary"
              onClick={() => {
                clear()
              }}
            >
              {t("search.clearAllCTA", "Clear all")}
            </Button>
            <Button
              className="w-full"
              onClick={() => setIsMobileFilterDrawerOpen(false)}
            >
              {t("search.doneCTA", "Done")}
            </Button>
          </Flex>
        </CustomDrawer>
      </Media>
      <Media greaterThanOrEqual={fullscreenBreakpoint}>
        {className => {
          return (
            <DesktopFilterContainer
              $isFooterShown={isFooterShown}
              className={className}
              isOpen={sidebarOpen}
              topOffset={topOffset ?? contentTopOffset}
              variant={variant}
            >
              <Block paddingRight="16px">
                {isFunction(children) ? children(closeIfMobile) : children}
              </Block>
            </DesktopFilterContainer>
          )
        }}
      </Media>
    </>
  )
}

export default SearchFilter

const CustomDrawer = styled(Drawer)`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const DesktopFilterContainer = styled(Block)<{
  topOffset: number
  isOpen: boolean
  $isFooterShown: boolean
  variant?: SearchFilterVariant
}>`
  flex-direction: column;
  position: sticky;
  top: ${props => `${props.topOffset}px`};
  height: calc(100vh - (${props => `${props.topOffset}px`}));
  width: ${props =>
    props.isOpen ? (props.variant === "profile" ? "450px" : "375px") : 0};
  min-width: ${props =>
    props.isOpen && props.variant === "profile" ? "350px" : undefined};
  overflow: auto;
  //NOTE(@auster-eth): This is to align the filter text content which has 10pixels of padding
  ${props =>
    props.isOpen &&
    css`
      margin-left: -10px;
      margin-right: 16px;
    `}

  ${props =>
    props.$isFooterShown &&
    css`
      padding-bottom: ${BOTTOM_ACTION_BAR_HEIGHT_PX};
    `}
`
