/**
 * @generated SignedSource<<a3ac0c9c7e80acf110cc70031ae686a9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PortfolioTableListingCell_item$data = {
  readonly orderData: {
    readonly bestAskForOwner: {
      readonly " $fragmentSpreads": FragmentRefs<"PortfolioTableListingCell_bestAskV2">;
    } | null;
    readonly bestAskV2: {
      readonly " $fragmentSpreads": FragmentRefs<"PortfolioTableListingCell_bestAskV2">;
    } | null;
  };
  readonly relayId: string;
  readonly " $fragmentSpreads": FragmentRefs<"PortfolioTableListingTooltip_item">;
  readonly " $fragmentType": "PortfolioTableListingCell_item";
};
export type PortfolioTableListingCell_item$key = {
  readonly " $data"?: PortfolioTableListingCell_item$data;
  readonly " $fragmentSpreads": FragmentRefs<"PortfolioTableListingCell_item">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "PortfolioTableListingCell_bestAskV2"
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "identity"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "PortfolioTableListingCell_item",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "relayId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ESOrderDataType",
      "kind": "LinkedField",
      "name": "orderData",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "OrderV2Type",
          "kind": "LinkedField",
          "name": "bestAskV2",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        },
        {
          "alias": "bestAskForOwner",
          "args": [
            {
              "kind": "Variable",
              "name": "byAddress",
              "variableName": "identity"
            }
          ],
          "concreteType": "OrderV2Type",
          "kind": "LinkedField",
          "name": "bestAskV2",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": [
        {
          "kind": "Variable",
          "name": "identity",
          "variableName": "identity"
        }
      ],
      "kind": "FragmentSpread",
      "name": "PortfolioTableListingTooltip_item"
    }
  ],
  "type": "ItemType",
  "abstractKey": "__isItemType"
};
})();

(node as any).hash = "0b73d0334c96e58c202e4f5edd9c6a19";

export default node;
