import React, { useEffect } from "react"
import { Text, Input, Flex } from "@opensea/ui-kit"
import { graphql, useFragment } from "react-relay"
import styled from "styled-components"
import { Panel } from "@/components/layout/Panel"
import {
  FilterBackground,
  FilterDivContainer,
} from "@/components/search/FilterBackground.react"
import { SearchDivider } from "@/components/search/SearchDivider.react"
import { Button } from "@/design-system/Button"
import { Form } from "@/design-system/Form"
import { FormControl } from "@/design-system/FormControl"
import { useForm } from "@/hooks/useForm"
import { useTranslate } from "@/hooks/useTranslate"
import { trackApplyRarityFilter } from "@/lib/analytics/events/searchEvents"
import { RarityFilterType } from "@/lib/graphql/__generated__/CollectionAssetSearchListQuery.graphql"
import { RarityFilter_data$key } from "@/lib/graphql/__generated__/RarityFilter_data.graphql"
import { display } from "@/lib/helpers/numberUtils"
import { EM_DASH } from "@/lib/helpers/stringUtils"

const MIN_RARITY_RANK = 1

const isEmpty = (value?: string): value is undefined =>
  value === undefined || value === ""

const areRarityFiltersEqual = (
  filter1: RarityFilterType,
  filter2: RarityFilterType,
) => {
  return (
    filter1.field == filter2.field &&
    filter1.min === filter2.min &&
    filter1.max === filter2.max
  )
}

type RarityFilterProps = {
  dataKey: RarityFilter_data$key
  rarityFilter?: RarityFilterType
  setRarityFilter: (rarityFilter: RarityFilterType) => unknown
}

export const RarityFilter = ({
  dataKey,
  rarityFilter,
  setRarityFilter,
}: RarityFilterProps) => {
  const t = useTranslate("components")
  const { collectionRarityData } = useFragment(
    graphql`
      fragment RarityFilter_data on CollectionType {
        collectionRarityData {
          maxRank
        }
      }
    `,
    dataKey,
  )

  const maxRank = collectionRarityData?.maxRank ?? 0
  // Consider max rank as infinite in validation if it cannot be determined from representative assets
  const maxRankForValidation =
    maxRank === 0 ? Number.POSITIVE_INFINITY : maxRank
  const maxLabel = maxRank === 0 ? "" : display(maxRank)

  const {
    register,
    formState: { errors, isValid, isDirty },
    watch,
    handleSubmit,
    reset,
  } = useForm<{ min?: string; max?: string }>({
    mode: "onChange",
    defaultValues: {
      min: rarityFilter?.min ?? undefined,
      max: rarityFilter?.max ?? undefined,
    },
  })

  const validateRange = (min?: string, max?: string) =>
    isEmpty(min) ||
    isEmpty(max) ||
    Number(min) <= Number(max) ||
    t(
      "rarityFilter.form.rangeError",
      "Min rank filter must be less than max rank filter",
    )

  const validateIsInteger = (value?: string) =>
    isEmpty(value) ||
    Number.isInteger(Number(value)) ||
    t("rarityFilter.form.integerError", "Rarity rank must be a whole number")

  const validateMinRankDomain = (value?: string) =>
    isEmpty(value) ||
    Number(value) <= maxRankForValidation ||
    t(
      "rarityFilter.form.minRarityDomainError",
      "Min rank filter exceeds max rarity rank",
    )

  const onSubmit = handleSubmit(formData => {
    const min = !isEmpty(formData.min)
      ? Math.max(Number(formData.min), MIN_RARITY_RANK).toString()
      : undefined
    const max = !isEmpty(formData.max)
      ? Math.min(Number(formData.max), maxRankForValidation).toString()
      : undefined

    const newRarityFilter: RarityFilterType = {
      field: "RANK",
      min,
      max,
    }

    if (
      !rarityFilter ||
      !areRarityFiltersEqual(rarityFilter, newRarityFilter)
    ) {
      trackApplyRarityFilter({ min, max })
      setRarityFilter(newRarityFilter)
    }

    reset({ min: min?.toString(), max: max?.toString() })
  })

  const [min, max] = watch(["min", "max"])
  const validateRangeResult = validateRange(min, max)
  const rangeError = validateRangeResult !== true && validateRangeResult

  useEffect(() => {
    reset({ min: rarityFilter?.min ?? "", max: rarityFilter?.max ?? "" })
  }, [rarityFilter?.min, rarityFilter?.max, reset])

  return (
    <FilterDivContainer>
      <Panel
        bodyClassName="FilterBackground--body"
        headerClassName="FilterBackground--header"
        id="filter-rarity"
        isHeaderPadded={false}
        mode="start-closed"
        title={t("rarityFilter.title", "Rarity rank")}
      >
        <FilterBackground className="px-2 pt-3">
          <Form onSubmit={onSubmit}>
            <FormControl
              error={rangeError || errors.min?.message || errors.max?.message}
              hideLabel
              label={t("rarityFilter.label", "Rarity filter")}
            >
              <Flex className="items-center justify-between">
                <StyledInput
                  error={!!rangeError || !!errors.min?.message}
                  {...register("min", {
                    required: false,
                    validate: {
                      validateIsInteger,
                      validateMinRankDomain,
                    },
                  })}
                  placeholder={MIN_RARITY_RANK.toString()}
                  type="number"
                  onBlur={e =>
                    (e.target.placeholder = MIN_RARITY_RANK.toString())
                  }
                  onFocus={e => (e.target.placeholder = "")}
                />
                <Text.Body asChild className="mx-4" size="small">
                  <div>{EM_DASH}</div>
                </Text.Body>
                <StyledInput
                  error={!!rangeError || !!errors.max?.message}
                  {...register("max", {
                    required: false,
                    validate: {
                      validateIsInteger,
                    },
                  })}
                  placeholder={maxLabel}
                  type="number"
                  onBlur={e => (e.target.placeholder = maxLabel)}
                  onFocus={e => (e.target.placeholder = "")}
                />
              </Flex>
            </FormControl>
            <Button
              className="my-4 w-full"
              disabled={!isValid || !isDirty || !!rangeError}
              type="submit"
            >
              {t("rarityFilter.apply", "Apply")}
            </Button>
          </Form>
          <SearchDivider />
        </FilterBackground>
      </Panel>
    </FilterDivContainer>
  )
}

const StyledInput = styled(Input)`
  input {
    text-align: center;
  }
`
