import React, { createContext, useState, useMemo, useContext } from "react"
import { noop } from "lodash"

type SidebarContext = {
  sidebarCollapsed: boolean
  setSidebarCollapsed: (sidebarCollapsed: boolean) => void
}

const DEFAULT_CONTEXT: SidebarContext = {
  sidebarCollapsed: false,
  setSidebarCollapsed: noop,
}

const SidebarContext = createContext(DEFAULT_CONTEXT)

type Props = {
  children: React.ReactNode
  initialSidebarCollapsedState?: boolean
}

export const SidebarContextProvider = ({
  children,
  initialSidebarCollapsedState = true,
}: Props) => {
  const [sidebarCollapsed, setSidebarCollapsed] = useState(
    initialSidebarCollapsedState,
  )
  const value = useMemo(
    () => ({ sidebarCollapsed, setSidebarCollapsed }),
    [sidebarCollapsed],
  )

  return (
    <SidebarContext.Provider value={value}>{children}</SidebarContext.Provider>
  )
}

export const useSidebarContext = () => useContext(SidebarContext)
