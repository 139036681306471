import React, { ReactNode, Suspense } from "react"
import {
  Text,
  FlexColumn,
  classNames,
  FlexColumnProps,
  Flex,
  FlexProps,
  Skeleton,
} from "@opensea/ui-kit"
import { ErrorBoundary } from "@sentry/nextjs"
import { differenceInMinutes } from "date-fns"
import {
  PreloadedQuery,
  useFragment,
  usePreloadedQuery,
  useQueryLoader,
} from "react-relay"
import styled from "styled-components"
import type { Placement } from "tippy.js"
import { ErrorFetchingData } from "@/components/common/ErrorFetchingData.react"
import { Block } from "@/design-system/Block"
import { Tooltip, TooltipOffset } from "@/design-system/Tooltip"
import { useOwnerListingPriceOnAccountPage } from "@/hooks/useFlag"
import { useTranslate } from "@/hooks/useTranslate"
import { PortfolioTableListingTooltip_item$key } from "@/lib/graphql/__generated__/PortfolioTableListingTooltip_item.graphql"
import { PortfolioTableListingTooltipContent_item$key } from "@/lib/graphql/__generated__/PortfolioTableListingTooltipContent_item.graphql"
import { PortfolioTableListingTooltipQuery as PortfolioTableListingTooltipQueryType } from "@/lib/graphql/__generated__/PortfolioTableListingTooltipQuery.graphql"
import { graphql } from "@/lib/graphql/graphql"
import { dateFromISO8601 } from "@/lib/helpers/datetime"
import { bn, displayFiat, quantityDisplay } from "@/lib/helpers/numberUtils"
import {
  getStatus,
  StatusIndicator,
} from "../cells/PortfolioTableListingCell.react"

const PortfolioTableListingTooltipQuery = graphql`
  query PortfolioTableListingTooltipQuery($order: OrderRelayID!) {
    order(order: $order) {
      perUnitPriceType {
        usd
        eth
      }
      closedAt
    }
  }
`

type Props = {
  item: PortfolioTableListingTooltip_item$key | null
  children: React.ReactNode
  disabled?: boolean
  placement?: Placement
  offset?: TooltipOffset
}

export const PortfolioTableListingTooltip = ({
  item: itemKey,
  disabled,
  placement,
  offset = [-16, 16],
  children,
}: Props) => {
  const item = useFragment(
    graphql`
      fragment PortfolioTableListingTooltip_item on ItemType
      @argumentDefinitions(identity: { type: "IdentityInputType!" }) {
        __typename
        orderData {
          bestAskV2 {
            relayId
          }
          bestAskForOwner: bestAskV2(byAddress: $identity) {
            relayId
          }
        }
        ... on AssetType {
          ...PortfolioTableListingTooltipContent_item
        }
      }
    `,
    itemKey,
  )

  const ownerListingPriceEnabled = useOwnerListingPriceOnAccountPage()
  const bestAsk = ownerListingPriceEnabled
    ? item?.orderData.bestAskForOwner
    : item?.orderData.bestAskV2

  // Use query loader instead of fragment to ensure data
  // gets updated on mutation.
  const [queryReference, loadQuery, disposeQuery] =
    useQueryLoader<PortfolioTableListingTooltipQueryType>(
      PortfolioTableListingTooltipQuery,
    )

  const shouldShowSkeletons = !queryReference

  if (item?.__typename === "AssetType") {
    return (
      <Tooltip
        animation="fade-vertical"
        arrow={false}
        className="rounded-xl"
        content={
          shouldShowSkeletons ? (
            <PortfolioTableListingTooltipSkeleton />
          ) : (
            <TooltipContainer>
              <ErrorBoundary
                fallback={({ resetError }) => (
                  <ErrorFetchingData resetError={resetError} />
                )}
              >
                <Suspense fallback={<PortfolioTableListingTooltipSkeleton />}>
                  <PortfolioTableListingTooltipContent
                    itemKey={item}
                    queryReference={queryReference}
                  />
                </Suspense>
              </ErrorBoundary>
            </TooltipContainer>
          )
        }
        contentPadding="0"
        disabled={disabled}
        maxWidth="auto"
        offset={offset}
        touch={false}
        onHide={() => disposeQuery()}
        onShow={() => {
          if (bestAsk) {
            loadQuery({
              order: bestAsk.relayId,
            })
          }
        }}
        {...(placement ? { placement } : {})}
      >
        <Block as="span">{children}</Block>
      </Tooltip>
    )
  }

  return <Block as="span">{children}</Block>
}

const PortfolioTableListingTooltipContent = ({
  queryReference,
  itemKey,
}: {
  itemKey: PortfolioTableListingTooltipContent_item$key
  queryReference: PreloadedQuery<PortfolioTableListingTooltipQueryType>
}) => {
  const item = useFragment(
    graphql`
      fragment PortfolioTableListingTooltipContent_item on AssetType {
        collection {
          statsV2 {
            floorPrice {
              eth
            }
          }
        }
      }
    `,
    itemKey,
  )

  const { order } = usePreloadedQuery(
    PortfolioTableListingTooltipQuery,
    queryReference,
  )

  const t = useTranslate("orders")

  const perUnitPrice = order.perUnitPriceType
  const statsV2 = item.collection.statsV2

  const closedAtDate = order.closedAt ? dateFromISO8601(order.closedAt) : null

  const indicatorStatusVariant = getStatus(closedAtDate)

  const rawFloorPrice = statsV2.floorPrice?.eth
  const floorPrice = rawFloorPrice ? bn(rawFloorPrice) : undefined
  const ratio =
    perUnitPrice.eth && floorPrice
      ? bn(perUnitPrice.eth).div(floorPrice)
      : undefined
  const diff = ratio?.minus(1)
  const percentageDiff = diff?.times(100)
  const usdPrice = perUnitPrice.usd
    ? displayFiat(bn(perUnitPrice.usd))
    : undefined

  const aboveFloorLabel = t("floorPriceDifference.above.label", "above")
  const belowFloorLabel = t("floorPriceDifference.below.label", "below")

  const formattedPercentageDiff = percentageDiff?.isZero()
    ? t("floorPriceDifference.atFloor", "At floor")
    : t("floorPriceDifference.notAtFloor", `{{quantity}}% {{percentageDiff}}`, {
        quantity: percentageDiff
          ? quantityDisplay(percentageDiff.abs().toFixed(0))
          : 0,
        percentageDiff: percentageDiff?.isNegative()
          ? belowFloorLabel
          : aboveFloorLabel,
      })

  return (
    <FlexColumn>
      <StatusData>
        <Flex className="items-center">
          <StatusIndicator marginRight="8px" variant={indicatorStatusVariant} />

          <Text size="small">
            {indicatorStatusVariant === "expiring" && closedAtDate
              ? t(
                  "expiring.tooltip",
                  "Expiring in {{differenceInMinutes}} minutes",
                  {
                    differenceInMinutes: differenceInMinutes(
                      closedAtDate,
                      new Date(),
                    ),
                  },
                )
              : t("listed.tooltip", "Listed")}
          </Text>
        </Flex>
      </StatusData>
      <ItemsContainer>
        <UsdData>
          <Text size="small">{usdPrice}</Text>
        </UsdData>
        <FloorDifferenceData>
          <Text size="small">{formattedPercentageDiff}</Text>
        </FloorDifferenceData>
      </ItemsContainer>
    </FlexColumn>
  )
}

const StatusData = ({ children }: { children: ReactNode }) => {
  const t = useTranslate("orders")

  return (
    <ItemsContainer className="border-b border-level-2">
      <ItemContainer>
        <ItemLabelContainer>
          <ItemLabel size="small">
            {t("tooltip.listing.status.header", "Status")}
          </ItemLabel>
        </ItemLabelContainer>
        {children}
      </ItemContainer>
    </ItemsContainer>
  )
}

const UsdData = ({ children }: { children: ReactNode }) => {
  const t = useTranslate("orders")

  return (
    <ItemContainer>
      <ItemLabelContainer>
        <ItemLabel size="small">
          {t("tooltip.listing.usd.header", "USD")}
        </ItemLabel>
      </ItemLabelContainer>
      {children}
    </ItemContainer>
  )
}

const FloorDifferenceData = ({ children }: { children: ReactNode }) => {
  const t = useTranslate("orders")

  return (
    <ItemContainer className="mt-1">
      <ItemLabelContainer>
        <ItemLabel size="small">
          {t("tooltip.listing.floorDifference.header", "Floor difference")}
        </ItemLabel>
      </ItemLabelContainer>
      {children}
    </ItemContainer>
  )
}

const PortfolioTableListingTooltipSkeleton = () => {
  return (
    <TooltipContainer>
      <FlexColumn>
        <StatusData>
          <Flex className="w-full items-center">
            <Skeleton.Circle className="h-2.5 w-2.5" />
            <Skeleton.Line className="ml-2 h-3 w-full min-w-[40px]" />
          </Flex>
        </StatusData>
        <ItemsContainer>
          <UsdData>
            <Skeleton.Line className="h-3 w-full min-w-[40px]" />
          </UsdData>
          <FloorDifferenceData>
            <Skeleton.Line className="h-3 w-full min-w-[40px]" />
          </FloorDifferenceData>
        </ItemsContainer>
      </FlexColumn>
    </TooltipContainer>
  )
}

const TooltipContainer = ({ className, ...props }: FlexProps) => (
  <Flex {...props} className={classNames("items-start text-left", className)} />
)

const ItemsContainer = ({ className, ...props }: FlexColumnProps) => (
  <FlexColumn className={classNames("px-4 py-3", className)} {...props} />
)

const ItemContainer = ({ className, ...props }: FlexProps) => (
  <Flex
    {...props}
    className={classNames("items-center justify-between", className)}
  />
)

const ItemLabelContainer = styled(Block)`
  min-width: 124px;
  margin-right: 16px;
`

const ItemLabel = styled(Text.Body)`
  font-weight: 600;
`
