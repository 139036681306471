import React, { useState, useMemo } from "react"
import { noop } from "lodash"
import { createContext, useContextSelector } from "use-context-selector"

type ProfileListingsContextContext = {
  canceledListingRelayIds: string[]
  setCanceledListingRelayIds: React.Dispatch<React.SetStateAction<string[]>>
  /** Relay ids of items whose listings have all been canceled. */
  canceledListingsItemRelayIds: string[]
  setCanceledListingsItemRelayIds: React.Dispatch<
    React.SetStateAction<string[]>
  >
  updatedItemRelayIds: string[]
  setUpdatedItemRelayIds: React.Dispatch<React.SetStateAction<string[]>>
}

const DEFAULT_CONTEXT: ProfileListingsContextContext = {
  canceledListingRelayIds: [],
  setCanceledListingRelayIds: noop,
  canceledListingsItemRelayIds: [],
  setCanceledListingsItemRelayIds: noop,
  updatedItemRelayIds: [],
  setUpdatedItemRelayIds: noop,
}

const ProfileListingsContext = createContext(DEFAULT_CONTEXT)
type Props = {
  children: React.ReactNode
}

export const ProfileListingsContextProvider = ({ children }: Props) => {
  const [canceledListingRelayIds, setCanceledListingRelayIds] = useState(
    DEFAULT_CONTEXT["canceledListingRelayIds"],
  )
  const [canceledListingsItemRelayIds, setCanceledListingsItemRelayIds] =
    useState(DEFAULT_CONTEXT["canceledListingsItemRelayIds"])
  const [updatedItemRelayIds, setUpdatedItemRelayIds] = useState(
    DEFAULT_CONTEXT["updatedItemRelayIds"],
  )

  const value = useMemo(
    () => ({
      canceledListingRelayIds,
      setCanceledListingRelayIds,
      canceledListingsItemRelayIds,
      setCanceledListingsItemRelayIds,
      updatedItemRelayIds,
      setUpdatedItemRelayIds,
    }),
    [
      canceledListingRelayIds,
      canceledListingsItemRelayIds,
      updatedItemRelayIds,
    ],
  )

  return (
    <ProfileListingsContext.Provider value={value}>
      {children}
    </ProfileListingsContext.Provider>
  )
}

export const useCanceledListingRelayIds = () => {
  return [
    useContextSelector(
      ProfileListingsContext,
      value => value.canceledListingRelayIds,
    ),
    useContextSelector(
      ProfileListingsContext,
      value => value.setCanceledListingRelayIds,
    ),
  ] as const
}

export const useCanceledListingsItemRelayIds = () => {
  return [
    useContextSelector(
      ProfileListingsContext,
      value => value.canceledListingsItemRelayIds,
    ),
    useContextSelector(
      ProfileListingsContext,
      value => value.setCanceledListingsItemRelayIds,
    ),
  ] as const
}

export const useUpdatedItemRelayIds = () => {
  return [
    useContextSelector(
      ProfileListingsContext,
      value => value.updatedItemRelayIds,
    ),
    useContextSelector(
      ProfileListingsContext,
      value => value.setUpdatedItemRelayIds,
    ),
  ] as const
}
