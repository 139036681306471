import React from "react"
import { Flex, Media, SwitchPill } from "@opensea/ui-kit"
import { Panel, PanelProps } from "@/components/layout/Panel"
import {
  FilterBackground,
  FilterDivContainer,
} from "@/components/search/FilterBackground.react"
import {
  StatusIndicator,
  StatusIndicatorType,
} from "@/features/account/components/PortfolioTable/components/cells/PortfolioTableListingCell.react"
import { useTranslate } from "@/hooks/useTranslate"
import { SearchToggle } from "@/lib/graphql/__generated__/AssetSearchListPaginationQuery.graphql"
import { getStatusVariant } from "../assets/AssetSearchFilter"
import { SearchDivider } from "../SearchDivider.react"

export type Item<T> = {
  filter: T
  label: string
}

type Props<T> = {
  setFilters: (filters: readonly T[]) => unknown
  filters: readonly T[]
  possibleFilterItems: readonly Item<T>[]
  title: string
  mode?: PanelProps["mode"]
  showOnMobileOnly?: boolean
}

export const FeaturedFilter = <T,>({
  setFilters,
  filters,
  possibleFilterItems,
  title,
  mode,
  showOnMobileOnly,
}: Props<T>) => {
  const t = useTranslate("components")

  const handleToggleFilter = (toggledFilter: T) => {
    const isCurrentlySelected = filters.includes(toggledFilter)
    const newFilters = new Set(filters)
    if (isCurrentlySelected) {
      newFilters.delete(toggledFilter)
    } else {
      newFilters.add(toggledFilter)
    }
    setFilters(Array.from(newFilters))
  }

  const renderPanel = () => (
    <FilterDivContainer>
      <Panel
        bodyClassName="FilterBackground--body"
        headerClassName="FilterBackground--header"
        id="featured-filter"
        isHeaderPadded={false}
        mode={mode}
        title={title}
        unmountChildrenOnClose
      >
        <FilterBackground>
          <Flex className="flex-wrap gap-3 px-2.5 py-3">
            <SwitchPill
              checked={filters.length === 0}
              onClick={() => setFilters([])}
            >
              <SwitchPill.Text>{t("statusFilter.all", "All")}</SwitchPill.Text>
            </SwitchPill>
            {possibleFilterItems.map(({ filter, label }) => {
              const statusIndicatorVariant: StatusIndicatorType | undefined =
                getStatusVariant(filter as SearchToggle)

              return (
                <SwitchPill
                  checked={filters.includes(filter)}
                  key={label}
                  onClick={() => handleToggleFilter(filter)}
                >
                  {statusIndicatorVariant && (
                    <StatusIndicator variant={statusIndicatorVariant} />
                  )}
                  <SwitchPill.Text>{label}</SwitchPill.Text>
                </SwitchPill>
              )
            })}
          </Flex>
          <SearchDivider />
        </FilterBackground>
      </Panel>
    </FilterDivContainer>
  )

  if (showOnMobileOnly) {
    return <Media lessThan="lg">{renderPanel()}</Media>
  }

  return renderPanel()
}
