import React, { useState } from "react"
import { SpaceBetween, Text, FlexColumn, Alert } from "@opensea/ui-kit"
import styled from "styled-components"
import { useDebounce } from "use-debounce"
import {
  FilterBackground,
  FilterDivContainer,
} from "@/components/search/FilterBackground.react"
import { Block } from "@/design-system/Block"
import { Button } from "@/design-system/Button"
import { Tooltip } from "@/design-system/Tooltip"
import { useTranslate } from "@/hooks/useTranslate"
import { Panel, PanelProps } from "../layout/Panel"
import { FilterSearchInput } from "./FilterSearchInput.react"
import { SearchOptions, SearchOptionsProps } from "./SearchOptions.react"
const DEBOUNCE_DEFAULT_MS = 250

type ProfileCollectionFilterProps = {
  searchCollectionItems: (query: string) => React.ReactNode
  mode?: PanelProps["mode"]
}

export const ProfileCollectionFilter = ({
  searchCollectionItems,
  mode = "start-open",
}: ProfileCollectionFilterProps) => {
  const t = useTranslate("components")
  const [query, setQuery] = useState("")
  const [debouncedQuery] = useDebounce(query, DEBOUNCE_DEFAULT_MS)

  return (
    <FilterDivContainer>
      <CollectionFilterPanel
        mode={mode}
        title={t("profileCollectionFilter.title", "Collections")}
      >
        <FilterBackground>
          <ProfileCollectionFilterSearchInput
            query={query}
            setQuery={setQuery}
          />
          <ProfileCollectionFilterHeader />
          {searchCollectionItems(debouncedQuery)}
        </FilterBackground>
      </CollectionFilterPanel>
    </FilterDivContainer>
  )
}

type ProfileCollectionFilterSearchInputProps = {
  query?: string
  setQuery?: React.Dispatch<React.SetStateAction<string>>
}

const ProfileCollectionFilterSearchInput = ({
  query,
  setQuery,
}: ProfileCollectionFilterSearchInputProps) => {
  const t = useTranslate("components")
  return (
    <Block marginY="8px" padding="0 8px">
      <FilterSearchInput
        placeholder={t("collectionFilter.search", "Search")}
        value={query}
        onChange={e => setQuery?.(e.target.value)}
      />
    </Block>
  )
}

const ProfileCollectionFilterHeader = () => {
  const t = useTranslate("components")
  return (
    <SpaceBetween className="px-2.5 py-3">
      <ColumnHeader>
        {t("profileCollectionFilter.collectionTitle", "Collection")}
      </ColumnHeader>
      <Tooltip
        content={() =>
          t(
            "profileCollectionFilter.tooltip",
            "Number of items times floor price",
          )
        }
      >
        <ColumnHeader>
          {t("profileCollectionFilter.value", "Value")}
        </ColumnHeader>
      </Tooltip>
    </SpaceBetween>
  )
}

type ProfileCollectionSearchOptionsProps = {
  items: SearchOptionsProps["items"]
  isShowMoreButtonVisible: boolean
  maxCollectionsReached: boolean
  showMore: () => void
  showMoreDisabled: boolean
}

export const ProfileCollectionSearchOptions = ({
  items,
  isShowMoreButtonVisible,
  maxCollectionsReached,
  showMoreDisabled,
  showMore,
}: ProfileCollectionSearchOptionsProps) => {
  const t = useTranslate("components")
  return (
    <>
      <SearchOptions
        items={items}
        name="collection-filter"
        type="row"
        withSearchDivider={!isShowMoreButtonVisible}
      />
      {isShowMoreButtonVisible && (
        <FlexColumn className="w-full items-center gap-3 px-2.5 pb-4 pt-[1px]">
          {maxCollectionsReached && (
            <Alert className="w-full">
              <Alert.Icon color="warning" size={20} value="error" />
              <Alert.Title size="small">
                {t(
                  "profileCollectionFilter.maxCollections",
                  "Max limit of 200 collections reached.",
                )}
              </Alert.Title>
            </Alert>
          )}
          <ShowMoreButton disabled={showMoreDisabled} onClick={showMore}>
            {t("profileCollectionFilter.showMore", "Show more")}
          </ShowMoreButton>
        </FlexColumn>
      )}
    </>
  )
}

const CollectionFilterPanel = styled(Panel).attrs({
  bodyClassName: "FilterBackground--body",
  headerClassName: "FilterBackground--header",
  id: "filter-collection",
  isHeaderPadded: false,
  unmountChildrenOnClose: true,
})``

const ShowMoreButton = styled(Button).attrs({
  variant: "secondary",
})`
  color: "blue";
  height: 48px;
  width: 100%;
`

const ColumnHeader = styled(Text.Body).attrs({
  className: "text-secondary uppercase",
  size: "tiny",
  weight: "semibold",
})``
