import React from "react"
import { Flex, Text, classNames } from "@opensea/ui-kit"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"
import styled from "styled-components"
import { variant } from "styled-system"
import { Block } from "@/design-system/Block"
import { PriceText } from "@/design-system/PriceText"
import { useCanceledListingsItemRelayIds } from "@/features/account/components/AccountPage/components/ProfileListingsContextProvider.react"
import { isExpiring } from "@/features/account/utils/status"
import { useOwnerListingPriceOnAccountPage } from "@/hooks/useFlag"
import { useTranslate } from "@/hooks/useTranslate"
import { PortfolioTableListingCell_bestAskV2$key } from "@/lib/graphql/__generated__/PortfolioTableListingCell_bestAskV2.graphql"
import { PortfolioTableListingCell_item$key } from "@/lib/graphql/__generated__/PortfolioTableListingCell_item.graphql"
import { dateFromISO8601 } from "@/lib/helpers/datetime"
import { PortfolioTableListingTooltip } from "../tooltips/PortfolioTableListingTooltip.react"

type Props = {
  item: PortfolioTableListingCell_item$key
}

export type StatusIndicatorType = "expiring" | "listed" | "notListed"

export const getStatus = (closedAtDate: Date | null) => {
  if (!closedAtDate) {
    return "notListed"
  }
  if (isExpiring(closedAtDate)) {
    return "expiring"
  }
  return "listed"
}

export const PortfolioTableListingCell = ({ item: itemKey }: Props) => {
  const t = useTranslate("account")
  const ownerListingPriceEnabled = useOwnerListingPriceOnAccountPage()

  const item = useFragment(
    graphql`
      fragment PortfolioTableListingCell_item on ItemType
      @argumentDefinitions(identity: { type: "IdentityInputType!" }) {
        relayId
        orderData {
          bestAskV2 {
            ...PortfolioTableListingCell_bestAskV2
          }
          bestAskForOwner: bestAskV2(byAddress: $identity) {
            ...PortfolioTableListingCell_bestAskV2
          }
        }
        ...PortfolioTableListingTooltip_item @arguments(identity: $identity)
      }
    `,
    itemKey,
  )

  const [canceledListingsItemRelayIds] = useCanceledListingsItemRelayIds()
  const bestAsk = useFragment<PortfolioTableListingCell_bestAskV2$key>(
    graphql`
      fragment PortfolioTableListingCell_bestAskV2 on OrderV2Type {
        perUnitPriceType {
          unit
          symbol
        }
        closedAt
      }
    `,
    canceledListingsItemRelayIds.includes(item.relayId)
      ? null
      : ownerListingPriceEnabled
      ? item.orderData.bestAskForOwner
      : item.orderData.bestAskV2,
  )

  const closedAtDate = bestAsk?.closedAt
    ? dateFromISO8601(bestAsk.closedAt)
    : null

  const indicatorStatusVariant = getStatus(closedAtDate)

  return (
    <PortfolioTableListingTooltip disabled={!bestAsk} item={item}>
      <Flex className="w-full items-center justify-end md:justify-start">
        <StatusIndicator marginRight="8px" variant={indicatorStatusVariant} />

        {bestAsk ? (
          <PriceText
            subtleEmpty
            symbol={bestAsk.perUnitPriceType.symbol}
            unit={bestAsk.perUnitPriceType.unit}
          />
        ) : (
          <Text.Body size="small">
            {t("portfolio.status.notListed", "Not listed")}
          </Text.Body>
        )}
      </Flex>
    </PortfolioTableListingTooltip>
  )
}

export const StatusIndicator = styled(Block).attrs<{
  variant: StatusIndicatorType
}>(props => ({
  className: classNames("rounded-full", props.className),
  flexShrink: 0,
  height: "8px",
  width: "8px",
}))<{ variant: StatusIndicatorType }>`
  ${props =>
    variant({
      variants: {
        expiring: { background: props.theme.colors.starFish },
        listed: { background: props.theme.colors.seaGrass },
        notListed: {
          background:
            props.theme.type === "light"
              ? props.theme.colors.pearlGray
              : props.theme.colors.darkGray,
        },
      },
    })}
`
