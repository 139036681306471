import React from "react"
import { Flex, Icon, UnstyledButton, classNames } from "@opensea/ui-kit"
import styled from "styled-components"
import { variant } from "styled-system"
import { Image } from "@/design-system/Image"
import { Values } from "@/lib/helpers/type"
import { themeVariant } from "@/styles/styleUtils"

const IconSize: Record<PillSizes, number> = {
  micro: 16,
  tiny: 20,
  small: 24,
  medium: 32,
}

export const PILL_SIZES = {
  MICRO: "micro",
  TINY: "tiny",
  SMALL: "small",
  MEDIUM: "medium",
} as const

type PillSizes = Values<typeof PILL_SIZES>

export type PillProps = {
  text: React.ReactNode
  alt?: string
  imageUrl?: string
  variant?: PillSizes
  onClose?: () => unknown
  overrides?: {
    Icon: {
      className: string
    }
  }
}

export const Pill = ({
  text,
  alt,
  imageUrl,
  overrides,
  variant = PILL_SIZES.MEDIUM,
  onClose,
}: PillProps) => {
  return (
    <PillContainer variant={variant}>
      {imageUrl && (
        <ImageContainer variant={variant}>
          <StyledImage
            alt={alt}
            height={IconSize[variant]}
            src={imageUrl}
            unoptimized
            width={IconSize[variant]}
          />
        </ImageContainer>
      )}
      {text}
      {onClose && (
        <UnstyledButton
          style={{ backgroundColor: "transparent" }}
          onClick={onClose}
        >
          <StyledIcon
            aria-label="Remove"
            className={classNames(overrides?.Icon.className)}
            size={20}
            value="close"
          />
        </UnstyledButton>
      )}
    </PillContainer>
  )
}

const PillContainer = styled(Flex)<Pick<PillProps, "variant">>`
  justify-content: space-between;
  align-items: center;

  ${variant({
    variants: {
      [PILL_SIZES.MICRO]: {
        borderRadius: "4px",
        padding: "2px 8px",
      },
      [PILL_SIZES.TINY]: {
        borderRadius: "12px",
        padding: "10px",
      },
      [PILL_SIZES.SMALL]: {
        borderRadius: "8px",
        padding: "12px 16px",
      },
      [PILL_SIZES.MEDIUM]: {
        borderRadius: "8px",
        padding: "12px 16px",
      },
    },
  })}
  background-color: ${props => props.theme.colors.components.background.gray1};
`

const ImageContainer = styled(Flex)<Pick<PillProps, "variant">>`
  background-color: ${props => props.theme.colors.base1};
  ${props =>
    props.variant !== PILL_SIZES.TINY &&
    themeVariant({
      variants: {
        dark: {
          border: "1px solid rgba(255, 255, 255, 0.1)",
        },
        light: {
          border: "1px solid rgba(0, 0, 0, 0.1)",
        },
      },
    })};
  border-radius: 6px;
  margin-right: 8px;
`

const StyledImage = styled(Image)`
  border-radius: ${props => props.theme.borderRadius.squircle};
  border: 1px solid ${props => props.theme.colors.components.border.level2};
`

const StyledIcon = styled(Icon)`
  margin-left: 8px;
  color: ${props => props.theme.colors.text.primary};
  border: none;
  background: none;

  :hover {
    color: ${props => props.theme.colors.text.secondary};
  }
`
