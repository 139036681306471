import React from "react"
import { Text } from "@opensea/ui-kit"
import { Panel } from "@/components/layout/Panel"
import {
  FilterBackground,
  FilterDivContainer,
} from "@/components/search/FilterBackground.react"
import { SearchOptions } from "@/components/search/SearchOptions.react"
import { useCategoriesInfo } from "@/hooks/useCategoriesInfo"
import { useTranslate } from "@/hooks/useTranslate"

type Props = {
  selectedCategories: ReadonlyArray<string>
  setCategoryFilter: (categories: ReadonlyArray<string>) => unknown
}

const CategoryFilter = ({ selectedCategories, setCategoryFilter }: Props) => {
  const t = useTranslate("components")
  const CATEGORIES = useCategoriesInfo()
  const items = CATEGORIES.map(category => {
    const isSelected =
      selectedCategories.find(
        selectedCategory => selectedCategory === category.slug,
      ) !== undefined

    return {
      id: category.slug,
      isSelected,
      label: (
        <Text asChild>
          <div>{category.name}</div>
        </Text>
      ),
      onChange: () => {
        const newCategories = isSelected
          ? selectedCategories.filter(c => c !== category.slug)
          : [...selectedCategories, category.slug]
        setCategoryFilter(newCategories)
      },
    }
  })

  return (
    <FilterDivContainer>
      <Panel
        bodyClassName="FilterBackground--body"
        headerClassName="FilterBackground--header"
        id="filter-category"
        isHeaderPadded={false}
        mode="start-closed"
        title={t("categoryFilter.title", "Category")}
        unmountChildrenOnClose
      >
        <FilterBackground>
          <SearchOptions items={items} name="category-filter" type="checkbox" />
        </FilterBackground>
      </Panel>
    </FilterDivContainer>
  )
}

export default React.memo(CategoryFilter)
