import React, { ComponentProps, useState } from "react"
import { uniqBy, times } from "lodash"
import { GLOBAL_MAX_PAGE_SIZE } from "@/constants/index"
import { useNoSuspenseLazyLoadQuery } from "@/hooks/useNoSuspenseLazyLoadQuery"
import {
  ProfileCollectionFilterItemsLazyQuery,
  ProfileCollectionFilterItemsLazyQuery$variables,
} from "@/lib/graphql/__generated__/ProfileCollectionFilterItemsLazyQuery.graphql"
import { SafelistRequestStatus } from "@/lib/graphql/__generated__/useCollectionFormEditMutation.graphql"
import { clearCache } from "@/lib/graphql/environment/middlewares/cacheMiddleware"
import { getNodes, graphql } from "@/lib/graphql/graphql"
import { ProfileCollectionSearchOptions } from "./ProfileCollectionFilter.react"
import { ProfileCollectionFilterItem } from "./ProfileCollectionFilterItem.react"
import { SearchOptions } from "./SearchOptions.react"

const MAX_COLLECTIONS = 200

type ProfileCollectionFilterItemsProps = {
  assetOwner: ProfileCollectionFilterItemsLazyQuery$variables["assetOwner"]
  query: string
  collectionSlugs: ProfileCollectionFilterItemsLazyQuery$variables["collections"]
  selectedSlugs: ReadonlyArray<string>
  setSlugs: (slugs: ReadonlyArray<string> | undefined) => unknown
  onlyPrivateAssets: boolean
  safelistRequestStatuses?: ReadonlyArray<SafelistRequestStatus> | null
}

export const ProfileCollectionFilterItems = ({
  query,
  assetOwner,
  selectedSlugs,
  setSlugs,
  collectionSlugs,
  onlyPrivateAssets,
  safelistRequestStatuses,
}: ProfileCollectionFilterItemsProps) => {
  const [collectionCount, setCollectionCount] =
    useState<number>(GLOBAL_MAX_PAGE_SIZE)

  const [collectionData, refetch] =
    useNoSuspenseLazyLoadQuery<ProfileCollectionFilterItemsLazyQuery>(
      graphql`
        query ProfileCollectionFilterItemsLazyQuery(
          $assetOwner: IdentityInputType!
          $collections: [CollectionSlug!]!
          $count: Int!
          $onlyPrivateAssets: Boolean!
          $query: String
          $cursor: String
          $safelistRequestStatuses: [SafelistRequestStatus!]
        ) {
          collectionsLimited(
            assetOwner: $assetOwner
            after: $cursor
            first: $count
            query: $query
            sortBy: EXPECTED_VALUE
            onlyPrivateAssets: $onlyPrivateAssets
            includeHidden: false
            safelistRequestStatuses: $safelistRequestStatuses
          ) @connection(key: "ProfileCollectionFilter_collectionsLimited") {
            edges {
              node {
                slug
                ...ProfileCollectionFilterItem_collection
                  @arguments(owner: $assetOwner)
              }
            }
          }
          selectedCollections: collectionsLimited(
            first: $count
            collections: $collections
            includeHidden: false
            sortBy: EXPECTED_VALUE
            safelistRequestStatuses: $safelistRequestStatuses
          ) {
            edges {
              node {
                slug
                ...ProfileCollectionFilterItem_collection
                  @arguments(owner: $assetOwner)
              }
            }
          }
        }
      `,
      {
        count: collectionCount,
        query,
        assetOwner,
        collections: collectionSlugs,
        onlyPrivateAssets,
        safelistRequestStatuses,
      },
    )

  if (!collectionData) {
    return <ProfileCollectionFilterSkeleton />
  }

  const collections = collectionData.collectionsLimited
  const selectedCollections = collectionData.selectedCollections

  const uniqueCollections = uniqBy(
    [...getNodes(selectedCollections), ...getNodes(collections)],
    c => c.slug,
  )

  const returnedCollectionCount = uniqueCollections.length
  const maxCollectionsReached = collectionCount >= MAX_COLLECTIONS
  const canPaginate = returnedCollectionCount === collectionCount
  const isShowMoreButtonVisible = returnedCollectionCount >= 20

  const sortedCollections = [
    ...uniqueCollections.filter(c => selectedSlugs.includes(c.slug)),
    ...uniqueCollections.filter(c => !selectedSlugs.includes(c.slug)),
  ]

  const items: ComponentProps<typeof SearchOptions>["items"] =
    sortedCollections.map(collection => {
      const isSelected = selectedSlugs.includes(collection.slug)
      return {
        id: collection.slug,
        isSelected,
        label: <ProfileCollectionFilterItem collection={collection} />,
        onChange: () => {
          // When an item is clicked, either remove it or add it to the selected collections.
          const newCollections = isSelected
            ? selectedSlugs.filter(c => c !== collection.slug)
            : [...selectedSlugs, collection.slug]
          setSlugs(newCollections.length ? newCollections : undefined)
        },
        collection,
      }
    })

  return (
    <ProfileCollectionSearchOptions
      isShowMoreButtonVisible={isShowMoreButtonVisible}
      items={items}
      maxCollectionsReached={maxCollectionsReached}
      showMore={() => {
        clearCache()
        setCollectionCount(collectionCount + GLOBAL_MAX_PAGE_SIZE)
        refetch({
          count: collectionCount,
        })
      }}
      showMoreDisabled={maxCollectionsReached || !canPaginate}
    />
  )
}

export const ProfileCollectionFilterSkeleton = () => {
  return (
    <>
      {times(GLOBAL_MAX_PAGE_SIZE).map(i => (
        <ProfileCollectionFilterItem.Skeleton key={i} />
      ))}
    </>
  )
}
