/**
 * @generated SignedSource<<b345c716ca4c0b774d22bb7468af15ac>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PortfolioTableListingTooltipContent_item$data = {
  readonly collection: {
    readonly statsV2: {
      readonly floorPrice: {
        readonly eth: string;
      } | null;
    };
  };
  readonly " $fragmentType": "PortfolioTableListingTooltipContent_item";
};
export type PortfolioTableListingTooltipContent_item$key = {
  readonly " $data"?: PortfolioTableListingTooltipContent_item$data;
  readonly " $fragmentSpreads": FragmentRefs<"PortfolioTableListingTooltipContent_item">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PortfolioTableListingTooltipContent_item",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "CollectionType",
      "kind": "LinkedField",
      "name": "collection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CollectionStatsV2Type",
          "kind": "LinkedField",
          "name": "statsV2",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "PriceType",
              "kind": "LinkedField",
              "name": "floorPrice",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "eth",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "AssetType",
  "abstractKey": null
};

(node as any).hash = "b4377dc2d49b2445fcacbe7c8c8a2e9e";

export default node;
