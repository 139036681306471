import React from "react"
import { Flex, Media, Text } from "@opensea/ui-kit"
import styled from "styled-components"
import { Panel } from "@/components/layout/Panel"
import {
  FilterBackground,
  FilterDivContainer,
} from "@/components/search/FilterBackground.react"
import { SearchOptions } from "@/components/search/SearchOptions.react"
import { Image } from "@/design-system/Image"
import { useChains } from "@/hooks/useChains"
import type { ChainIdentifier } from "@/hooks/useChains/types"
import { useTranslate } from "@/hooks/useTranslate"

type Props = {
  activeChains: ReadonlyArray<ChainIdentifier>
  allowMultichain?: boolean
  setChains: (values?: ReadonlyArray<ChainIdentifier>) => unknown
  showOnMobileOnly?: boolean
}

export const ChainFilter = ({
  activeChains,
  allowMultichain = true,
  setChains,
  showOnMobileOnly,
}: Props) => {
  const t = useTranslate("components")
  const { chains, getChainName, getChainLogo, isChainFiltersEnabled } =
    useChains()

  const getUpdatedChains = (chain: ChainIdentifier) => {
    if (activeChains.includes(chain)) {
      return activeChains.filter((c: ChainIdentifier) => c !== chain)
    }
    if (allowMultichain) {
      return [...activeChains, chain]
    }
    return [chain]
  }

  const items = chains
    .filter(chain => isChainFiltersEnabled(chain))
    .map(chain => {
      return {
        id: chain,
        isSelected: activeChains.includes(chain),
        label: (
          <Flex className="items-center">
            <Flex className="mr-4">
              <RoundImage
                alt={getChainName(chain)}
                height={32}
                objectFit="cover"
                src={getChainLogo(chain)}
                width={32}
              />
            </Flex>
            <Text asChild>
              <div>{getChainName(chain)}</div>
            </Text>
          </Flex>
        ),
        onChange: () => {
          const newChains = getUpdatedChains(chain)
          setChains(newChains.length ? newChains : undefined)
        },
      }
    })

  const renderChainPanel = () => (
    <FilterDivContainer>
      <Panel
        bodyClassName="FilterBackground--body"
        headerClassName="FilterBackground--header"
        id="filter-chain"
        isHeaderPadded={false}
        mode="start-closed"
        title={t("chainFilter.title", "Chains")}
        unmountChildrenOnClose
      >
        <FilterBackground>
          <SearchOptions items={items} name="chain-filter" type="checkbox" />
        </FilterBackground>
      </Panel>
    </FilterDivContainer>
  )

  if (showOnMobileOnly) {
    return <Media lessThan="lg">{renderChainPanel()}</Media>
  }

  return renderChainPanel()
}

const RoundImage = styled(Image)`
  border-radius: ${props => props.theme.borderRadius.circle};
`
