import React from "react"
import { Panel } from "@/components/layout/Panel"
import {
  FilterBackground,
  FilterDivContainer,
} from "@/components/search/FilterBackground.react"
import { SearchOptions } from "@/components/search/SearchOptions.react"
import { useTranslate } from "@/hooks/useTranslate"

export type Props = {
  setIsAutoHidden: (isAutoHidden?: boolean) => unknown
  isAutoHidden?: boolean | null
}

export const HiddenStatusFilter = ({
  setIsAutoHidden,
  isAutoHidden,
}: Props) => {
  const t = useTranslate("components")
  const isAutoHiddenDefined =
    isAutoHidden !== undefined && isAutoHidden !== null
  const filterOptions = [
    {
      id: "hidden-by-you",
      isSelected: isAutoHiddenDefined && !isAutoHidden,
      label: t("hiddenStatusFilter.byYou", "Hidden by you"),
      onChange: () => {
        // isAutoHidden is a radio button state that can be undefined, false, or true.
        // We have to un-set this state back to undefined if the user clicks the same button a second time.
        setIsAutoHidden(isAutoHidden === false ? undefined : false)
      },
    },
    {
      id: "auto-hidden",
      isSelected: isAutoHiddenDefined && isAutoHidden,
      label: t("hiddenStatusFilter.autoHidden", "Auto-hidden"),
      onChange: () => {
        // isAutoHidden is a radio button state that can be undefined, false, or true.
        // We have to un-set this state back to undefined if the user clicks the same button a second time.
        setIsAutoHidden(isAutoHidden === true ? undefined : true)
      },
    },
  ]

  return (
    <FilterDivContainer>
      <Panel
        bodyClassName="FilterBackground--body"
        headerClassName="FilterBackground--header"
        id="hidden-status-filter"
        isHeaderPadded={false}
        mode="start-open"
        title={t("hiddenStatusFilter.title", "Hidden Status")}
        unmountChildrenOnClose
      >
        <FilterBackground>
          <SearchOptions
            items={filterOptions}
            name="hidden-filters"
            type="checkbox"
          />
        </FilterBackground>
      </Panel>
    </FilterDivContainer>
  )
}
