/**
 * @generated SignedSource<<b6eec6057d843dd51e1a25fd735d5b34>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PortfolioTableListingTooltip_item$data = {
  readonly __typename: string;
  readonly orderData: {
    readonly bestAskForOwner: {
      readonly relayId: string;
    } | null;
    readonly bestAskV2: {
      readonly relayId: string;
    } | null;
  };
  readonly " $fragmentSpreads": FragmentRefs<"PortfolioTableListingTooltipContent_item">;
  readonly " $fragmentType": "PortfolioTableListingTooltip_item";
};
export type PortfolioTableListingTooltip_item$key = {
  readonly " $data"?: PortfolioTableListingTooltip_item$data;
  readonly " $fragmentSpreads": FragmentRefs<"PortfolioTableListingTooltip_item">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "relayId",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "identity"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "PortfolioTableListingTooltip_item",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ESOrderDataType",
      "kind": "LinkedField",
      "name": "orderData",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "OrderV2Type",
          "kind": "LinkedField",
          "name": "bestAskV2",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        },
        {
          "alias": "bestAskForOwner",
          "args": [
            {
              "kind": "Variable",
              "name": "byAddress",
              "variableName": "identity"
            }
          ],
          "concreteType": "OrderV2Type",
          "kind": "LinkedField",
          "name": "bestAskV2",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PortfolioTableListingTooltipContent_item"
        }
      ],
      "type": "AssetType",
      "abstractKey": null
    }
  ],
  "type": "ItemType",
  "abstractKey": "__isItemType"
};
})();

(node as any).hash = "1aa3b738863718471c15b730b797388b";

export default node;
