/**
 * @generated SignedSource<<dd316cfa3568096ee56c508974d7399b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SearchProperties_collection$data = {
  readonly numericTraits: ReadonlyArray<{
    readonly key: string;
    readonly value: {
      readonly max: number;
      readonly min: number;
    };
    readonly " $fragmentSpreads": FragmentRefs<"NumericTraitFilter_data">;
  }>;
  readonly slug: string;
  readonly stringTraits: ReadonlyArray<{
    readonly key: string;
    readonly " $fragmentSpreads": FragmentRefs<"StringTraitFilter_data">;
  }>;
  readonly " $fragmentType": "SearchProperties_collection";
};
export type SearchProperties_collection$key = {
  readonly " $data"?: SearchProperties_collection$data;
  readonly " $fragmentSpreads": FragmentRefs<"SearchProperties_collection">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "key",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SearchProperties_collection",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "NumericTraitTypePair",
      "kind": "LinkedField",
      "name": "numericTraits",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "NumericTraitType",
          "kind": "LinkedField",
          "name": "value",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "max",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "min",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "NumericTraitFilter_data"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "StringTraitType",
      "kind": "LinkedField",
      "name": "stringTraits",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "StringTraitFilter_data"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "CollectionType",
  "abstractKey": null
};
})();

(node as any).hash = "43695fa3bea54427663276c13902ef76";

export default node;
