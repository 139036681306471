import React from "react"
import { Separator } from "@opensea/ui-kit"
import { classNames } from "@/styles/styleUtils"

type Props = {
  hideScrollbar?: boolean
}

export const SearchDivider = ({ hideScrollbar }: Props) => {
  return (
    <Separator
      className={classNames("mb-2 ml-2.5 mr-3", hideScrollbar && "mt-4")}
    />
  )
}
