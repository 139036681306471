/**
 * @generated SignedSource<<a68cc7986bf889634626f85cd13ee81a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type CollectionSort = "AVERAGE_PRICE" | "CREATED_DATE" | "MARKET_CAP" | "NAME" | "NUM_OWNERS" | "NUM_REPORTS" | "ONE_DAY_VOLUME" | "ONE_DAY_VOLUME_DIFF" | "ONE_HOUR_SALES" | "ONE_HOUR_VOLUME" | "SEVEN_DAY_AVERAGE_PRICE" | "SEVEN_DAY_CHANGE" | "SEVEN_DAY_SALES" | "SEVEN_DAY_VOLUME" | "SIX_HOUR_VOLUME" | "THIRTY_DAY_VOLUME" | "TOTAL_SALES" | "TOTAL_SUPPLY" | "TOTAL_VOLUME" | "%future added value";
export type IdentityInputType = {
  address?: string | null;
  name?: string | null;
  username?: string | null;
};
export type CollectionFilterLazyQuery$variables = {
  assetCreator?: IdentityInputType | null;
  assetOwner?: IdentityInputType | null;
  categories?: ReadonlyArray<string> | null;
  chains?: ReadonlyArray<| "ETHEREUM" | "MATIC" | "KLAYTN" | "BSC" | "SOLANA" | "ARBITRUM" | "ARBITRUM_NOVA" | "AVALANCHE" | "OPTIMISM" | "OPTIMISM_SEPOLIA" | "RINKEBY" | "MUMBAI" | "AMOY" | "BAOBAB" | "BSC_TESTNET" | "GOERLI" | "SOLDEV" | "ARBITRUM_GOERLI" | "ARBITRUM_SEPOLIA" | "AVALANCHE_FUJI" | "OPTIMISM_GOERLI" | "SEPOLIA" | "BASE" | "BASE_GOERLI" | "BASE_SEPOLIA" | "BLAST" | "BLAST_SEPOLIA" | "GUNZILLA" | "GUNZILLA_TESTNET" | "SEI" | "SEI_DEVNET" | "SEI_TESTNET" | "ZORA" | "ZORA_TESTNET" | "ZORA_SEPOLIA" | "B3_SEPOLIA" | "FLOW" | "FLOW_TESTNET" | "APE_CURTIS"| "B3" | "APE_CHAIN" | "SONEIUM_MINATO" | "MONAD_TESTNET" | "SONEIUM" | "RONIN" | "SHAPE" | "BERA_CHAIN"  | "UNICHAIN" | "SAIGON_TESTNET" | "%future added value"> | null;
  collections?: ReadonlyArray<string> | null;
  count?: number | null;
  includeHidden?: boolean | null;
  onlyPrivateAssets?: boolean | null;
  query?: string | null;
  sortBy?: CollectionSort | null;
};
export type CollectionFilterLazyQuery$data = {
  readonly collections: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly assetCount: number | null;
        readonly imageUrl: string | null;
        readonly isVerified: boolean;
        readonly name: string;
        readonly slug: string;
      } | null;
    } | null>;
  };
  readonly selectedCollections: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly assetCount: number | null;
        readonly imageUrl: string | null;
        readonly isVerified: boolean;
        readonly name: string;
        readonly slug: string;
      } | null;
    } | null>;
  };
};
export type CollectionFilterLazyQuery = {
  response: CollectionFilterLazyQuery$data;
  variables: CollectionFilterLazyQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "assetCreator"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "assetOwner"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "categories"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "chains"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "collections"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "count"
  },
  {
    "defaultValue": false,
    "kind": "LocalArgument",
    "name": "includeHidden"
  },
  {
    "defaultValue": false,
    "kind": "LocalArgument",
    "name": "onlyPrivateAssets"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "query"
  },
  {
    "defaultValue": "SEVEN_DAY_VOLUME",
    "kind": "LocalArgument",
    "name": "sortBy"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "assetCreator",
    "variableName": "assetCreator"
  },
  {
    "kind": "Variable",
    "name": "assetOwner",
    "variableName": "assetOwner"
  },
  {
    "kind": "Variable",
    "name": "chains",
    "variableName": "chains"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  },
  {
    "kind": "Variable",
    "name": "includeHidden",
    "variableName": "includeHidden"
  },
  {
    "kind": "Variable",
    "name": "onlyPrivateAssets",
    "variableName": "onlyPrivateAssets"
  },
  {
    "kind": "Variable",
    "name": "parents",
    "variableName": "categories"
  },
  {
    "kind": "Variable",
    "name": "query",
    "variableName": "query"
  },
  {
    "kind": "Variable",
    "name": "sortBy",
    "variableName": "sortBy"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "assetCount",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "imageUrl",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isVerified",
  "storageKey": null
},
v7 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "CollectionTypeEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CollectionType",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v8 = [
  {
    "kind": "Variable",
    "name": "collections",
    "variableName": "collections"
  },
  {
    "kind": "Literal",
    "name": "first",
    "value": 25
  },
  {
    "kind": "Literal",
    "name": "includeHidden",
    "value": true
  }
],
v9 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "CollectionTypeEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CollectionType",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CollectionFilterLazyQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CollectionTypeConnection",
        "kind": "LinkedField",
        "name": "collections",
        "plural": false,
        "selections": (v7/*: any*/),
        "storageKey": null
      },
      {
        "alias": "selectedCollections",
        "args": (v8/*: any*/),
        "concreteType": "CollectionTypeConnection",
        "kind": "LinkedField",
        "name": "collections",
        "plural": false,
        "selections": (v7/*: any*/),
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CollectionFilterLazyQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CollectionTypeConnection",
        "kind": "LinkedField",
        "name": "collections",
        "plural": false,
        "selections": (v9/*: any*/),
        "storageKey": null
      },
      {
        "alias": "selectedCollections",
        "args": (v8/*: any*/),
        "concreteType": "CollectionTypeConnection",
        "kind": "LinkedField",
        "name": "collections",
        "plural": false,
        "selections": (v9/*: any*/),
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f95056e93ceac5e187bf64497d4a153d",
    "id": null,
    "metadata": {},
    "name": "CollectionFilterLazyQuery",
    "operationKind": "query",
    "text": "query CollectionFilterLazyQuery(\n  $assetCreator: IdentityInputType\n  $assetOwner: IdentityInputType\n  $categories: [CollectionSlug!]\n  $chains: [ChainScalar!]\n  $collections: [CollectionSlug!]\n  $count: Int\n  $includeHidden: Boolean = false\n  $onlyPrivateAssets: Boolean = false\n  $query: String\n  $sortBy: CollectionSort = SEVEN_DAY_VOLUME\n) {\n  collections(assetOwner: $assetOwner, assetCreator: $assetCreator, onlyPrivateAssets: $onlyPrivateAssets, chains: $chains, first: $count, includeHidden: $includeHidden, parents: $categories, query: $query, sortBy: $sortBy) {\n    edges {\n      node {\n        assetCount\n        imageUrl\n        name\n        slug\n        isVerified\n        id\n      }\n    }\n  }\n  selectedCollections: collections(first: 25, collections: $collections, includeHidden: true) {\n    edges {\n      node {\n        assetCount\n        imageUrl\n        name\n        slug\n        isVerified\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "8348ab5de16cf5d7331ba06d8d7f5bfd";

export default node;
