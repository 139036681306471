import React, { useState } from "react"
import { Media, Input, Text, Flex } from "@opensea/ui-kit"
import { useFragment } from "react-relay"
import styled from "styled-components"
import { Overflow } from "@/components/common/Overflow"
import { Panel, PanelProps } from "@/components/layout/Panel"
import { FilterDivContainer } from "@/components/search/FilterBackground.react"
import { SearchDivider } from "@/components/search/SearchDivider.react"
import { Block } from "@/design-system/Block"
import { Button } from "@/design-system/Button"
import { useTranslate } from "@/hooks/useTranslate"
import { RangeType } from "@/lib/graphql/__generated__/AssetsPageQuery.graphql"
import { NumericTraitFilter_data$key } from "@/lib/graphql/__generated__/NumericTraitFilter_data.graphql"
import { graphql } from "@/lib/graphql/graphql"
import { bn } from "@/lib/helpers/numberUtils"
import { themeVariant } from "@/styles/styleUtils"

type Props = {
  data: NumericTraitFilter_data$key
  range?: RangeType
  setRange: (range?: RangeType) => unknown
}

const equalNumericTraitFilters = (a: RangeType, b: RangeType) => {
  return a.max === b.max && a.min === b.min
}

export const NumericTraitFilter = ({
  data: dataKey,
  range,
  setRange,
}: Props) => {
  const t = useTranslate("components")
  const data = useFragment(
    graphql`
      fragment NumericTraitFilter_data on NumericTraitTypePair {
        key
        value {
          max
          min
        }
      }
    `,
    dataKey,
  )
  const { key, value } = data
  const [min, setMin] = useState(
    range ? range.min.toString() : value.min.toString(),
  )
  const [max, setMax] = useState(
    range ? range.max.toString() : value.max.toString(),
  )

  const invalid =
    !!min && !!max
      ? bn(min).isNaN() || bn(max).isNaN() || bn(min).gt(max)
      : false

  const apply = (): void => {
    if (invalid) {
      return
    }

    const currMin = parseFloat(min)
    const currMax = parseFloat(max)
    const inputRange: RangeType = {
      min: `${
        isNaN(currMin) || currMin > currMax ? range?.min || value.min : currMin
      }`,
      max: `${
        isNaN(currMax) || currMax < currMin ? range?.max || value.max : currMax
      }`,
    }

    setMin(inputRange.min.toString())
    setMax(inputRange.max.toString())

    if (
      equalNumericTraitFilters(
        inputRange,
        range ?? { min: `${value.min}`, max: `${value.max}` },
      )
    ) {
      return
    }

    setRange(inputRange)
  }

  const panelChildren = (
    <>
      <Flex className="mt-3 items-center justify-center bg-base-1">
        <InputContainer
          placeholder={t("numericTraitFilter.min.placeholder", "Min")}
          value={min}
          onChange={e => setMin(e.target.value)}
        />
        <Text.Heading asChild size="tiny">
          <div>{t("numericTraitFilter.to", "to")}</div>
        </Text.Heading>
        <InputContainer
          placeholder={t("numericTraitFilter.max.placeholder", "Max")}
          value={max}
          onChange={e => setMax(e.target.value)}
        />
      </Flex>
      {invalid && (
        <Block marginTop="16px">
          <Text color="error" size="small" weight="semibold">
            {t(
              "numericTraitFilter.validation.minLessThanMax",
              "Minimum must be less than maximum",
            )}
          </Text>
        </Block>
      )}
      <Button className="mt-4 w-full" disabled={invalid} onClick={apply}>
        {t("numericTraitFilter.apply", "Apply")}
      </Button>
      <SearchDivider />
    </>
  )

  const panelProps: Omit<PanelProps, "title"> = {
    bodyClassName: "FilterBackground--body",
    headerClassName: "FilterBackground--header",
    id: "filter-numeric-trait",
    isHeaderPadded: false,
    mode: "start-closed",
  }

  return (
    <FilterDivContainer>
      <Media lessThan="lg">
        <Panel
          {...panelProps}
          title={
            <Overflow overrides={{ Tooltip: { disabled: true } }}>
              {key}
            </Overflow>
          }
        >
          {panelChildren}
        </Panel>
      </Media>
      <Media greaterThanOrEqual="lg">
        <Panel {...panelProps} title={<Overflow>{key}</Overflow>}>
          {panelChildren}
        </Panel>
      </Media>
    </FilterDivContainer>
  )
}

const InputContainer = styled(Input)`
  align-items: center;
  background: ${props => props.theme.colors.base1};
  margin: 0 8px;
  height: 44px;
  ${props =>
    themeVariant({
      variants: {
        light: { border: `1px solid ${props.theme.colors.fog}` },
        dark: { border: `1px solid  ${props.theme.colors.ash}` },
      },
    })}
  border-radius: ${props => props.theme.borderRadius.button};
  input {
    text-align: center;
    font-weight: 400;
    font-size: 16px;
  }
`
