/**
 * @generated SignedSource<<cb0e68e26373a573e12e9333e66045b4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ChainIdentifier = "AMOY" | "APE_CHAIN" | "APE_CURTIS" | "ARBITRUM" | "ARBITRUM_GOERLI" | "ARBITRUM_NOVA" | "ARBITRUM_SEPOLIA" | "AVALANCHE" | "AVALANCHE_FUJI" | "B3" | "B3_SEPOLIA" | "BAOBAB" | "BASE" | "BASE_GOERLI" | "BASE_SEPOLIA" | "BERA_CHAIN" | "BLAST" | "BLAST_SEPOLIA" | "BSC" | "BSC_TESTNET" | "ETHEREUM" | "FLOW" | "FLOW_TESTNET" | "GOERLI" | "GUNZILLA_TESTNET" | "KLAYTN" | "MATIC" | "MONAD_TESTNET" | "MUMBAI" | "OPTIMISM" | "OPTIMISM_GOERLI" | "OPTIMISM_SEPOLIA" | "RINKEBY" | "RONIN" | "SAIGON_TESTNET" | "SEI" | "SEI_DEVNET" | "SEI_TESTNET" | "SEPOLIA" | "SHAPE" | "SOLANA" | "SOLDEV" | "SONEIUM" | "SONEIUM_MINATO" | "UNICHAIN" | "ZORA" | "ZORA_SEPOLIA" | "ZORA_TESTNET" | "%future added value";
export type RarityStrategyId = "OPENRARITY" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type AssetSearchFilter_data$data = {
  readonly collection: {
    readonly defaultChain: {
      readonly identifier: ChainIdentifier;
    };
    readonly enabledRarities: ReadonlyArray<RarityStrategyId>;
    readonly " $fragmentSpreads": FragmentRefs<"ListingsSupportCreatorFilter_collection" | "RarityFilter_data" | "SearchProperties_collection" | "useIsCollectionFooterEnabled_collection" | "useIsRarityEnabled_collection">;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"PaymentFilter_data">;
  readonly " $fragmentType": "AssetSearchFilter_data";
};
export type AssetSearchFilter_data$key = {
  readonly " $data"?: AssetSearchFilter_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"AssetSearchFilter_data">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "Variable",
    "name": "collection",
    "variableName": "collection"
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "collection"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "AssetSearchFilter_data",
  "selections": [
    {
      "alias": null,
      "args": (v0/*: any*/),
      "concreteType": "CollectionType",
      "kind": "LinkedField",
      "name": "collection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ChainType",
          "kind": "LinkedField",
          "name": "defaultChain",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "identifier",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "enabledRarities",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "RarityFilter_data"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "useIsRarityEnabled_collection"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "useIsCollectionFooterEnabled_collection"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SearchProperties_collection"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ListingsSupportCreatorFilter_collection"
        }
      ],
      "storageKey": null
    },
    {
      "args": (v0/*: any*/),
      "kind": "FragmentSpread",
      "name": "PaymentFilter_data"
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "73c5f1c8c4a443ac86f521c0497fd8d1";

export default node;
