import React, { useState } from "react"
import { Flex, Text } from "@opensea/ui-kit"
import _ from "lodash"
import { useFragment } from "react-relay"
import styled from "styled-components"
import { CollectionAssetsPreviewTooltip } from "@/components/collections/CollectionAssetsPreviewTooltip.react"
import { Overflow } from "@/components/common/Overflow"
import { Panel } from "@/components/layout/Panel"
import {
  FilterBackground,
  FilterDivContainer,
} from "@/components/search/FilterBackground.react"
import { FilterSearchInput } from "@/components/search/FilterSearchInput.react"
import {
  SearchOptions,
  SearchOptionsItem,
} from "@/components/search/SearchOptions.react"
import { Block } from "@/design-system/Block"
import { useIsTraitHoverPreviewEnabled } from "@/hooks/useFlag"
import { useTranslate } from "@/hooks/useTranslate"
import { TraitInputType } from "@/lib/graphql/__generated__/CollectionAssetsPreviewQuery.graphql"
import { StringTraitFilter_data$key } from "@/lib/graphql/__generated__/StringTraitFilter_data.graphql"
import { graphql } from "@/lib/graphql/graphql"
import { flatMap } from "@/lib/helpers/array"
import { quantityDisplay } from "@/lib/helpers/numberUtils"
import { strip } from "@/lib/helpers/stringUtils"

type Props = {
  data: StringTraitFilter_data$key
  setValues: (values?: ReadonlyArray<string>) => unknown
  values: ReadonlyArray<string>
  collectionSlug: string
  selectedStringTraits?: readonly TraitInputType[]
  hidePreview?: boolean
}

export const StringTraitFilter = React.memo(function StringTraitFilter({
  data: dataKey,
  setValues,
  values,
  collectionSlug,
  selectedStringTraits = [],
  hidePreview = false,
}: Props) {
  const t = useTranslate("components")
  const [query, setQuery] = useState("")
  const isProjectPeriscopeEnabled = useIsTraitHoverPreviewEnabled()

  const data = useFragment(
    graphql`
      fragment StringTraitFilter_data on StringTraitType {
        counts {
          count
          value
        }
        key
      }
    `,
    dataKey,
  )

  const valueSet = new Set(values)

  const items: ReadonlyArray<SearchOptionsItem> = flatMap(
    _.orderBy(data.counts, ["count", "value"], ["desc", "asc"]),
    ({ count, value }) => {
      if (!value.toLowerCase().includes(query.toLowerCase())) {
        return []
      }
      const checked = valueSet.has(value)
      const Tooltip = ({ children }: { children: React.ReactNode }) =>
        isProjectPeriscopeEnabled && !hidePreview ? (
          <CollectionAssetsPreviewTooltip
            placement="right-start"
            slug={collectionSlug}
            stringTraits={[
              ...selectedStringTraits,
              { name: data.key, values: [value] },
            ]}
          >
            <div>{children}</div>
          </CollectionAssetsPreviewTooltip>
        ) : (
          <div>{children}</div>
        )
      return [
        {
          id: value,
          isSelected: checked,
          Tooltip,
          label: (
            <Flex className="w-[88%] items-center">
              <NameContainer>
                <Text asChild>
                  <div>
                    <Overflow>{value}</Overflow>
                  </div>
                </Text>
              </NameContainer>
              <Block marginLeft="auto">
                <Text
                  asChild
                  className="text-left"
                  color="secondary"
                  size="small"
                >
                  <div>{quantityDisplay(count)}</div>
                </Text>
              </Block>
            </Flex>
          ),
          onChange: () => {
            const newValues = checked
              ? values.filter(v => v !== value)
              : [...values, value]
            setValues(newValues.length ? newValues : undefined)
          },
        },
      ]
    },
  )

  const title = (
    <Flex className="mr-1 w-full items-center">
      <Block flex={1} textAlign="left">
        <Overflow lines={1}> {data.key}</Overflow>
      </Block>
      <Text asChild color="secondary" size="small">
        <div>{data.counts.length}</div>
      </Text>
    </Flex>
  )

  return (
    <FilterDivContainer>
      <Panel
        bodyClassName="FilterBackground--body"
        headerClassName="FilterBackground--header"
        id={`trait-filter-${strip(data.key)}`}
        isHeaderPadded={false}
        mode="start-closed"
        title={title}
        unmountChildrenOnClose
      >
        <FilterBackground>
          <Block marginY="8px" padding="0 8px">
            <Text.Heading asChild className="font-normal" size="tiny">
              <div>
                <FilterSearchInput
                  placeholder={t("stringTraitFilter.search", "Search")}
                  value={query}
                  onChange={e => setQuery(e.target.value)}
                />
              </div>
            </Text.Heading>
          </Block>
          <SearchOptions
            items={items}
            name="string-trait-filter"
            type="checkbox"
          />
        </FilterBackground>
      </Panel>
    </FilterDivContainer>
  )
})

const NameContainer = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-bottom: 2px;
  margin-right: 12px;
`
