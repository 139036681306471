/* istanbul ignore file */
import React from "react"
import { Flex, Text, Skeleton } from "@opensea/ui-kit"
import { graphql, useFragment } from "react-relay"
import styled from "styled-components"
import { VerificationIcon } from "@/components/collections/VerificationIcon.react"
import { Item } from "@/design-system/Item"
import { PriceText } from "@/design-system/PriceText"
import { useTranslate } from "@/hooks/useTranslate"
import { ProfileCollectionFilterItem_collection$key } from "@/lib/graphql/__generated__/ProfileCollectionFilterItem_collection.graphql"
import {
  display,
  MAX_DISPLAYED_DECIMAL_PLACES,
} from "@/lib/helpers/numberUtils"
import { themeVariant } from "@/styles/styleUtils"
import { borderRadius } from "@/styles/themes"
import { Overflow } from "../common/Overflow"

type Props = {
  collection: ProfileCollectionFilterItem_collection$key | null
}

const IMAGE_SIZE = 36

const ProfileCollectionFilterItemBase = ({
  collection: collectionDataKey,
}: Props) => {
  const t = useTranslate("components")
  const collection = useFragment(
    graphql`
      fragment ProfileCollectionFilterItem_collection on CollectionType
      @argumentDefinitions(owner: { type: "IdentityInputType!" }) {
        logo
        name
        slug
        isVerified
        collectionAssetOwner(identity: $owner) {
          listedQuantity
          ownedQuantity
          expectedValue {
            unit
            symbol
          }
        }
        statsV2 {
          floorPrice {
            unit
            symbol
          }
        }
      }
    `,
    collectionDataKey,
  )

  if (!collection) {
    return null
  }

  const { collectionAssetOwner, logo, name, isVerified, slug, statsV2 } =
    collection

  return (
    <ProfileCollectionItem key={slug}>
      <ProfileCollectionAvatar size={IMAGE_SIZE} src={logo ?? ""} />
      <Item.Content className="mr-1">
        <Item.Title className="flex w-full">
          <OverflowStyled
            overrides={{
              Tooltip: {
                animation: "fade-vertical",
                variant: "card",
                borderRadius: borderRadius.large,
              },
            }}
          >
            <Text className="truncate" size="small">
              {name}
            </Text>
          </OverflowStyled>
          {isVerified && (
            <VerificationIcon
              showTooltip={false}
              size="tiny"
              verificationStatus="VERIFIED"
            />
          )}
        </Item.Title>
        <Item.Description className="text-secondary" size="tiny">
          {t(
            "profileCollectionFilter.listedOverOwned",
            "Listed: {{listedQuantity}}/{{ownedQuantity}}",
            {
              listedQuantity: collectionAssetOwner?.listedQuantity
                ? display(collectionAssetOwner.listedQuantity)
                : 0,
              ownedQuantity: collectionAssetOwner?.ownedQuantity
                ? display(collectionAssetOwner.ownedQuantity)
                : 0,
            },
          )}
        </Item.Description>
      </Item.Content>
      <Item.Side>
        {/* Ensure height and text match height of title in item content. */}
        <Item.Title asChild size="small">
          <PriceText
            roundBelowDecimals={MAX_DISPLAYED_DECIMAL_PLACES}
            size="small"
            symbol={collectionAssetOwner?.expectedValue?.symbol}
            unit={collectionAssetOwner?.expectedValue?.unit}
          />
        </Item.Title>
        <Item.Description className="text-secondary" size="tiny">
          {t("profileCollectionFilter.floorPrice", "Floor: {{price}}", {
            price: (
              <PriceText
                color="inherit"
                roundBelowDecimals={MAX_DISPLAYED_DECIMAL_PLACES}
                size="tiny"
                symbol={statsV2.floorPrice?.symbol}
                unit={statsV2.floorPrice?.unit}
              />
            ),
          })}
        </Item.Description>
      </Item.Side>
    </ProfileCollectionItem>
  )
}

const ProfileCollectionItem = styled(Item).attrs({
  variant: "unstyled",
  interactive: false,
})`
  min-width: 0;
`

const ProfileCollectionAvatar = styled(Item.Avatar)`
  border: 1px solid ${props => props.theme.colors.components.border.level2};
  border-radius: ${props => props.theme.borderRadius.default};

  ${props =>
    themeVariant({
      variants: {
        dark: {
          backgroundColor: props.theme.colors.oil,
        },
        light: {
          backgroundColor: props.theme.colors.fog,
        },
      },
    })}
`

const ProfileCollectionFilterItemSkeleton = () => {
  return (
    <Flex className="items-center px-2 py-2.5">
      <Skeleton.Block
        className="mr-4 rounded-default"
        style={{ height: IMAGE_SIZE, width: IMAGE_SIZE }}
      />
      <Skeleton>
        <Skeleton.Row>
          <Skeleton.Line className="h-2.5 w-1/2" />
          <Skeleton.Line className="h-2.5 w-[30%]" />
        </Skeleton.Row>
        <Skeleton.Row>
          <Skeleton.Line className="h-2.5 w-[30%]" />
          <Skeleton.Line className="h-2.5 w-[30%]" />
        </Skeleton.Row>
      </Skeleton>
    </Flex>
  )
}

export const ProfileCollectionFilterItem = Object.assign(
  ProfileCollectionFilterItemBase,
  {
    Skeleton: ProfileCollectionFilterItemSkeleton,
    Avatar: ProfileCollectionAvatar,
    Item: ProfileCollectionItem,
  },
)

const OverflowStyled = styled(Overflow)`
  width: auto;
`
