import React from "react"
import { FlexColumn, FlexColumnProps, classNames } from "@opensea/ui-kit"
import styled from "styled-components"
import { PanelBody, PanelHeader } from "../layout/BasePanel.react"

export const FilterBackground = ({ className, ...props }: FlexColumnProps) => (
  <FlexColumn className={classNames("bg-transparent", className)} {...props} />
)

export const FilterDivContainer = styled.div`
  .Panel--toggle {
    color: ${props => props.theme.colors.text.secondary};
  }

  ${PanelBody} {
    border-top: none;
  }

  ${PanelHeader} {
    border-top: none;
  }

  .Panel--panel {
    border: none;
    background: transparent;
  }

  .FilterBackground--body {
    padding: 0;
  }

  .FilterBackground--header {
    padding: 0 10px;
    height: 48px;
    font-size: 16px;
    background: transparent;
    text-align: left;

    @media (hover: hover) {
      :hover:not([disabled]) {
        background: ${props => props.theme.colors.components.background.gray1};
      }
    }

    :active:not([disabled]):active:not([disabled]),
    :focus:not([disabled]):focus:not([disabled]) {
      background: transparent;
    }
  }

  // NOTE(@laurafiuza): Hack to make the scrollbar in the subsections not show. We add extra margin
  // and hide the x overflow in order to "remove" the scrollbar so we can to avoid two scrollbars.
  .Scrollbox--content {
    overflow-x: hidden;
  }
`
