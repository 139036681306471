import React, { ComponentProps, ReactNode } from "react"
import { Flex, RadioGroup } from "@opensea/ui-kit"
import { Block } from "@/design-system/Block"
import { SearchDivider } from "./SearchDivider.react"
import { SearchOption } from "./SearchOption.react"

export type SearchOptionsItem = {
  id: string
  Tooltip?: React.ComponentType<{ children: ReactNode }>
} & Omit<ComponentProps<typeof SearchOption>, "name">

export type SearchOptionsProps = {
  name: string
  type: ComponentProps<typeof SearchOption>["type"]
  items: ReadonlyArray<SearchOptionsItem>
  withSearchDivider?: boolean
}

export const SearchOptions = ({
  name,
  type,
  items,
  withSearchDivider = true,
}: SearchOptionsProps) => {
  const options = items.map(
    ({ type: itemOverrideType, Tooltip, ...itemsRest }) => {
      const content = (
        <Flex asChild className="w-full" key={itemsRest.id}>
          <li>
            <SearchOption
              name={name}
              type={itemOverrideType || type}
              {...itemsRest}
            />
          </li>
        </Flex>
      )
      if (Tooltip) {
        return <Tooltip key={itemsRest.id}>{content}</Tooltip>
      }
      return content
    },
  )
  // NOTE(@laurafiuza): Negative 16px margin-right and padding-right 16px are a hack to make the
  // scrollbar in the subsections not show. We add extra margin and hide the x overflow
  // (see file ./FilterBackground.react.tsx) in order to "remove" the scrollbar.
  return (
    <>
      <Block
        as="ul"
        className="gap-1"
        display="flex"
        flexDirection="column"
        marginTop="0px"
      >
        {type === "radio" ? (
          <RadioGroup
            className="gap-0"
            value={items.find(item => item.isSelected)?.id}
            onValueChange={value =>
              items.find(item => item.id === value)?.onChange?.(true)
            }
          >
            {options}
          </RadioGroup>
        ) : (
          options
        )}
      </Block>
      {withSearchDivider && <SearchDivider hideScrollbar />}
    </>
  )
}
