import React, { ReactNode, ComponentProps } from "react"
import { RadioGroup, Text, classNames, Checkbox, Flex } from "@opensea/ui-kit"
import styled, { css } from "styled-components"

type SearchOptionProps = {
  label: ReactNode
  isSelected?: boolean
  onChange?: (checked: boolean) => unknown
  name: string
  // non-interactive-row is a row to render out your custom component that doesn't have any of the default row styles
  type?: "radio" | "checkbox" | "row" | "non-interactive-row"
  inputProps?: Omit<Partial<ComponentProps<typeof Checkbox>>, "type">
} & Omit<JSX.IntrinsicElements["label"], "onClick" | "ref" | "color">

const Container = styled(Text.Body)<{
  disabled: boolean
  $isSelected?: boolean
  $interactive?: boolean
}>`
  width: 100%;
  display: flex;
  align-items: center;
  height: 56px;
  align-items: center;
  background: transparent;
  border-radius: ${props => props.theme.borderRadius.button};

  ${props =>
    props.$isSelected &&
    css`
      background: ${props.theme.colors.components.background.gray1};
    `}

  ${props =>
    props.$interactive
      ? css`
          cursor: pointer;

          @media (hover: hover) {
            :hover:not([disabled]) {
              background: ${props =>
                props.theme.colors.components.background.gray1};
            }
          }

          :active:not([disabled]):active:not([disabled]),
          :focus:not([disabled]):focus:not([disabled]) {
            background: ${props =>
              props.theme.colors.components.background.gray2};
          }
        `
      : ""}

  ${props =>
    props.disabled
      ? css`
          opacity: 0.2;
          cursor: initial;
        `
      : ""}
`

export const SearchOption = ({
  label,
  isSelected,
  onChange,
  inputProps,
  type = "row",
  id,
  ...rest
}: SearchOptionProps) => {
  return (
    <Container
      {...rest}
      $interactive={type !== "non-interactive-row"}
      $isSelected={type === "row" ? Boolean(isSelected) : undefined}
      aria-label={id ? id.replace(/\s+/g, "_") : undefined}
      asChild
      className={classNames("mb-3 px-2.5 py-0 text-primary", rest.className)}
      disabled={!!inputProps?.disabled}
      id={id ? id.replace(/\s+/g, "_") : undefined}
      size="medium"
      onClick={type === "row" ? () => onChange?.(!isSelected) : undefined}
    >
      <label>
        {type !== "row" && type !== "non-interactive-row" && (
          <Flex className="mr-3">
            {type === "checkbox" ? (
              <Checkbox
                checked={Boolean(isSelected)}
                onCheckedChange={onChange}
                {...inputProps}
              />
            ) : (
              <RadioGroup.Item value={id ?? ""} />
            )}
          </Flex>
        )}

        {label}
      </label>
    </Container>
  )
}
