import React, { ComponentProps, Suspense } from "react"
import { useFragment, useLazyLoadQuery } from "react-relay"
import { FeaturedFilter } from "@/components/search/featured-filter"
import { ProfileCollectionFilter } from "@/components/search/ProfileCollectionFilter.react"
import { useIsRarityEnabled } from "@/components/search/rarity"
import type { ChainIdentifier } from "@/hooks/useChains/types"
import { Search } from "@/hooks/useSearch"
import { useTranslate } from "@/hooks/useTranslate"
import {
  IdentityInputType,
  PriceFilterType,
  RangeType,
  SearchToggle,
} from "@/lib/graphql/__generated__/AccountCollectedAssetSearchListQuery.graphql"
import {
  AssetSearchFilter_data$data,
  AssetSearchFilter_data$key,
} from "@/lib/graphql/__generated__/AssetSearchFilter_data.graphql"
import {
  AssetSearchFilterLazyQuery,
  AssetSearchFilterLazyQuery$variables,
} from "@/lib/graphql/__generated__/AssetSearchFilterLazyQuery.graphql"
import { RarityFilterType } from "@/lib/graphql/__generated__/CollectionAssetSearchListQuery.graphql"
import { graphql } from "@/lib/graphql/graphql"
import { CollectionFilter } from "../../CollectionFilter.react"
import { ListingsSupportCreatorFilter } from "../../ListingsSupportCreatorFilter.react"
import { ProfileCollectionFilterItems } from "../../ProfileCollectionFilterItems"
import { RarityFilter } from "../../rarity/RarityFilter.react"
import SearchFilter, { SearchFilterProps } from "../../SearchFilter.react"
import { ChainFilter } from "../ChainFilter.react"
import { HiddenStatusFilter } from "../HiddenStatusFilter"
import { OwnerFilter } from "../OwnerFilter.react"
import { PaymentFilter } from "../PaymentFilter.react"
import { PriceFilter } from "../PriceFilter.react"
import { SearchProperties } from "../SearchProperties.react"
import CategoryFilter from "./../CategoryFilter.react"
import { useCollectionPageStatusItems, useDefaultStatusItems } from "./utils"

type Props = Omit<AssetSearchFilterContentProps, "close" | "data"> &
  Pick<SearchFilterProps, "clear" | "topOffset"> & {
    dataKey: AssetSearchFilter_data$key | null
    renderFn?: (
      renderContent: (close: () => unknown) => JSX.Element,
    ) => JSX.Element
  }

export const SuspendedLazyAssetSearchFilter = (
  props: Omit<Props, "dataKey">,
) => {
  return (
    <Suspense fallback={null}>
      <LazyAssetSearchFilter {...props} />
    </Suspense>
  )
}

const searchStateToVariables = (
  state: AssetSearchFilterContentProps["state"],
): AssetSearchFilterLazyQuery$variables => ({
  collection: state.collection,
})

const LazyAssetSearchFilter = (props: Omit<Props, "dataKey">) => {
  const dataKey = useLazyLoadQuery<AssetSearchFilterLazyQuery>(
    graphql`
      query AssetSearchFilterLazyQuery($collection: CollectionSlug) {
        filter: query {
          ...AssetSearchFilter_data @arguments(collection: $collection)
        }
      }
    `,
    searchStateToVariables(props.state),
  )
  return <AssetSearchFilter {...props} dataKey={dataKey.filter} />
}

const AssetSearchFilter = ({
  clear,
  dataKey,
  setCategoryFilter,
  setChains,
  setCollectionSlugs,
  setNumericTrait,
  setPaymentAssets,
  setPriceFilter,
  setRarityFilter,
  setStringTrait,
  setOwner,
  setToggles,
  setIsAutoHidden,
  state,
  isCollectionPage = false,
  includeCollectionFilter = true,
  includeCategoryFilter = true,
  includeChainFilter = true,
  includeProfileCollectionFilter,
  isPrivateTab,
  renderFn,
  overrides,
  showChainFilterOnMobileOnly,
  showStatusFilterOnMobileOnly,
  includeListingsSupportCreatorFilter,
  setListingSupportsCreator,
  topOffset,
}: Props) => {
  const data = useFragment(
    graphql`
      fragment AssetSearchFilter_data on Query
      @argumentDefinitions(collection: { type: "CollectionSlug" }) {
        collection(collection: $collection) {
          defaultChain {
            identifier
          }
          enabledRarities
          ...RarityFilter_data
          ...useIsRarityEnabled_collection
          ...useIsCollectionFooterEnabled_collection
          ...SearchProperties_collection
          ...ListingsSupportCreatorFilter_collection
        }
        ...PaymentFilter_data @arguments(collection: $collection)
      }
    `,
    dataKey,
  )

  const renderContent = (close: () => unknown) => {
    return (
      <AssetSearchFilterContent
        close={close}
        data={data}
        includeCategoryFilter={includeCategoryFilter}
        includeChainFilter={includeChainFilter}
        includeCollectionFilter={includeCollectionFilter}
        includeListingsSupportCreatorFilter={
          includeListingsSupportCreatorFilter
        }
        includeProfileCollectionFilter={includeProfileCollectionFilter}
        isCollectionPage={isCollectionPage}
        isPrivateTab={isPrivateTab}
        overrides={overrides}
        setCategoryFilter={setCategoryFilter}
        setChains={setChains}
        setCollectionSlugs={setCollectionSlugs}
        setIsAutoHidden={setIsAutoHidden}
        setListingSupportsCreator={setListingSupportsCreator}
        setNumericTrait={setNumericTrait}
        setOwner={setOwner}
        setPaymentAssets={setPaymentAssets}
        setPriceFilter={setPriceFilter}
        setRarityFilter={setRarityFilter}
        setStringTrait={setStringTrait}
        setToggles={setToggles}
        showChainFilterOnMobileOnly={showChainFilterOnMobileOnly}
        showStatusFilterOnMobileOnly={showStatusFilterOnMobileOnly}
        state={state}
      />
    )
  }

  if (renderFn) {
    return renderFn(renderContent)
  }

  const variant =
    includeCollectionFilter || includeProfileCollectionFilter
      ? "profile"
      : "default"

  return (
    <SearchFilter
      clear={clear}
      fullscreenBreakpoint="lg"
      topOffset={topOffset}
      variant={variant}
    >
      {renderContent}
    </SearchFilter>
  )
}

type AssetSearchFilterContentProps = {
  state: Search
  setToggles?: (toggles?: ReadonlyArray<SearchToggle>) => Promise<unknown>
  setPriceFilter?: (filter?: PriceFilterType) => Promise<unknown>
  setRarityFilter?: (filter?: RarityFilterType) => Promise<unknown>
  setListingSupportsCreator?: (supportsCreator: boolean) => Promise<unknown>
  isCollectionPage?: boolean
  includeCollectionFilter?: boolean
  // TODO: We need to break up this component so that it's more composable
  includeProfileCollectionFilter?: boolean
  includeCategoryFilter?: boolean
  includeChainFilter?: boolean
  includeListingsSupportCreatorFilter?: boolean
  isPrivateTab?: boolean
  setCollectionSlugs?: (
    slugs: ReadonlyArray<string> | undefined,
  ) => Promise<unknown>
  setChains?: (chain?: ReadonlyArray<ChainIdentifier>) => Promise<unknown>
  setOwner?: (owner?: IdentityInputType) => Promise<unknown>
  setNumericTrait?: (key: string, range?: RangeType) => Promise<unknown>
  setPaymentAssets?: (paymentAssets?: ReadonlyArray<string>) => Promise<unknown>
  setStringTrait?: (
    key: string,
    values?: ReadonlyArray<string>,
  ) => Promise<unknown>
  setIsAutoHidden?: (isAutoHidden?: boolean) => unknown
  data: AssetSearchFilter_data$data | null
  setCategoryFilter?: (categories: ReadonlyArray<string>) => unknown
  close: () => unknown
  overrides?: {
    CollectionFilter?: {
      props: Partial<ComponentProps<typeof CollectionFilter>>
    }
    ProfileCollectionFilter?: {
      props: Partial<ComponentProps<typeof ProfileCollectionFilter>>
    }
  }
  showChainFilterOnMobileOnly?: boolean
  showStatusFilterOnMobileOnly?: boolean
}

const AssetSearchFilterContent = ({
  state,
  setToggles,
  setPriceFilter,
  setRarityFilter,
  setListingSupportsCreator,
  isCollectionPage,
  includeCollectionFilter,
  includeProfileCollectionFilter,
  includeListingsSupportCreatorFilter,
  includeCategoryFilter,
  includeChainFilter,
  isPrivateTab,
  setCollectionSlugs,
  setChains,
  setOwner,
  setNumericTrait,
  setPaymentAssets,
  setStringTrait,
  setCategoryFilter,
  setIsAutoHidden,
  data,
  close,
  overrides,
  showChainFilterOnMobileOnly,
  showStatusFilterOnMobileOnly,
}: AssetSearchFilterContentProps) => {
  const t = useTranslate("components")
  const isRarityDisplayed = useIsRarityEnabled(data?.collection ?? null)

  // Keep this optional chaining here to avoid a bug where defaultChain is undefined for some collections
  // Current suspicion is some relay store issue
  // https://opensea.sentry.io/issues/3942500046/?project=277230&query=reading+%27identifier%27&referrer=issue-stream&statsPeriod=14d
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const defaultChain = data?.collection?.defaultChain?.identifier

  const defaultStatusItems = useDefaultStatusItems()
  const collectionPageStatusItems = useCollectionPageStatusItems()
  const statusItems = isCollectionPage
    ? collectionPageStatusItems
    : defaultStatusItems

  const assetOwner = state.identity

  return (
    <>
      {isPrivateTab ? (
        <>
          {setIsAutoHidden && (
            <HiddenStatusFilter
              isAutoHidden={state.isAutoHidden}
              setIsAutoHidden={setIsAutoHidden}
            />
          )}
          {includeProfileCollectionFilter &&
            setCollectionSlugs &&
            assetOwner && (
              <ProfileCollectionFilter
                searchCollectionItems={query => (
                  <ProfileCollectionFilterItems
                    assetOwner={assetOwner}
                    collectionSlugs={state.collections ?? []}
                    onlyPrivateAssets
                    query={query}
                    safelistRequestStatuses={state.safelistRequestStatuses}
                    selectedSlugs={state.collections ? state.collections : []}
                    setSlugs={setCollectionSlugs}
                  />
                )}
              />
            )}
          {includeChainFilter && setChains && (
            <ChainFilter
              activeChains={state.chains || []}
              setChains={setChains}
              showOnMobileOnly={showChainFilterOnMobileOnly}
            />
          )}
        </>
      ) : (
        <>
          {includeProfileCollectionFilter &&
            setCollectionSlugs &&
            assetOwner && (
              <ProfileCollectionFilter
                searchCollectionItems={query => (
                  <ProfileCollectionFilterItems
                    assetOwner={assetOwner}
                    collectionSlugs={state.collections ?? []}
                    onlyPrivateAssets={false}
                    query={query}
                    safelistRequestStatuses={state.safelistRequestStatuses}
                    selectedSlugs={state.collections ? state.collections : []}
                    setSlugs={setCollectionSlugs}
                  />
                )}
                {...overrides?.ProfileCollectionFilter?.props}
              />
            )}
          {includeCollectionFilter && setCollectionSlugs && (
            <CollectionFilter
              filters={{
                ...searchStateToVariables(state),
                collections: state.collections,
                categories: state.categories,
                assetCreator: state.identity,
              }}
              selectedSlugs={state.collections ? state.collections : []}
              setSlugs={setCollectionSlugs}
              {...overrides?.CollectionFilter?.props}
            />
          )}

          {includeChainFilter && setChains && (
            <ChainFilter
              activeChains={state.chains || []}
              setChains={setChains}
              showOnMobileOnly={showChainFilterOnMobileOnly}
            />
          )}

          {setToggles && (
            <FeaturedFilter
              filters={state.toggles || []}
              mode="start-open"
              possibleFilterItems={statusItems}
              setFilters={setToggles}
              showOnMobileOnly={showStatusFilterOnMobileOnly}
              title={t("assetSearchFilter.status.title", "Status")}
            />
          )}
          {includeListingsSupportCreatorFilter &&
            setListingSupportsCreator &&
            data?.collection && (
              <ListingsSupportCreatorFilter
                collection={data.collection}
                setSupportsCreator={setListingSupportsCreator}
                supportsCreator={Boolean(
                  state.filterOutListingsWithoutRequestedCreatorFees,
                )}
              />
            )}
          {setOwner && <OwnerFilter owner={state.owner} setOwner={setOwner} />}
          {setPriceFilter && (
            <PriceFilter
              defaultChain={defaultChain}
              priceFilter={state.priceFilter ?? undefined}
              setPriceFilter={filter => {
                setPriceFilter(filter)
                close()
              }}
            />
          )}
          {setRarityFilter && isRarityDisplayed && data?.collection && (
            <RarityFilter
              dataKey={data.collection}
              rarityFilter={state.rarityFilter ?? undefined}
              setRarityFilter={filter => {
                setRarityFilter(filter)
                close()
              }}
            />
          )}

          {includeCategoryFilter && setCategoryFilter && (
            <CategoryFilter
              selectedCategories={state.categories ?? []}
              setCategoryFilter={setCategoryFilter}
            />
          )}
          {setPaymentAssets && (
            <PaymentFilter
              activeSymbols={state.paymentAssets || []}
              data={data}
              isCollectionPage={isCollectionPage}
              setPaymentAssets={setPaymentAssets}
            />
          )}
          {setStringTrait && (
            <SearchProperties
              collection={data?.collection ?? null}
              selectedNumericTraits={state.numericTraits}
              selectedStringTraits={state.stringTraits}
              setNumericTrait={setNumericTrait}
              setStringTrait={setStringTrait}
            />
          )}
        </>
      )}
    </>
  )
}

export default AssetSearchFilter
