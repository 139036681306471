import React, { useMemo } from "react"
import { Text } from "@opensea/ui-kit"
import { orderBy } from "lodash"
import { graphql, useFragment } from "react-relay"
import { SearchDivider } from "@/components/search/SearchDivider.react"
import { useTranslate } from "@/hooks/useTranslate"
import { TraitInputType } from "@/lib/graphql/__generated__/AccountCollectedAssetSearchListQuery.graphql"
import { TraitRangeType } from "@/lib/graphql/__generated__/AssetSearchListPaginationQuery.graphql"
import { RangeType } from "@/lib/graphql/__generated__/CollectionAssetSearchListQuery.graphql"
import { SearchProperties_collection$key } from "@/lib/graphql/__generated__/SearchProperties_collection.graphql"
import { NumericTraitFilter } from "./NumericTraitFilter.react"
import { StringTraitFilter } from "./StringTraitFilter.react"

type Props = {
  collection: SearchProperties_collection$key | null
  selectedStringTraits?: readonly TraitInputType[]
  selectedNumericTraits?: readonly TraitRangeType[]
  setNumericTrait?: (key: string, range?: RangeType) => Promise<unknown>
  setStringTrait: (key: string, values?: ReadonlyArray<string>) => unknown
  hideNumericTraits?: boolean
}

export const SearchProperties = ({
  selectedStringTraits,
  selectedNumericTraits,
  collection: collectionDataKey,
  setStringTrait,
  setNumericTrait,
  // TODO: Separate out this component into a separate string filter and numeric filter
  hideNumericTraits,
}: Props) => {
  const t = useTranslate("assets")
  const collection = useFragment(
    graphql`
      fragment SearchProperties_collection on CollectionType {
        slug
        numericTraits {
          key
          value {
            max
            min
          }
          ...NumericTraitFilter_data
        }
        stringTraits {
          key
          ...StringTraitFilter_data
        }
      }
    `,
    collectionDataKey,
  )
  const slug = collection?.slug
  const collectionStringTraits = useMemo(
    () => (collection ? orderBy(collection.stringTraits, "key") : []),
    [collection],
  )

  const collectionNumericTraits = orderBy(
    collection?.numericTraits.filter(t => t.value.max !== t.value.min),
    "key",
  )

  const stringTraitFilters = useMemo(() => {
    return (
      slug &&
      collectionStringTraits.map(t => (
        <StringTraitFilter
          collectionSlug={slug}
          data={t}
          key={t.key}
          selectedStringTraits={selectedStringTraits}
          setValues={values => setStringTrait(t.key, values)}
          values={
            selectedStringTraits?.find(st => st.name === t.key)?.values || []
          }
        />
      ))
    )
  }, [collectionStringTraits, selectedStringTraits, setStringTrait, slug])

  if (!collectionStringTraits.length && !collectionNumericTraits.length) {
    return null
  }

  return (
    <>
      <SearchDivider />
      <Text.Body
        asChild
        className="mb-4 mr-2.5 mt-6 px-2.5"
        size="medium"
        weight="semibold"
      >
        <h3>{t("traits.title", "Traits")}</h3>
      </Text.Body>
      {stringTraitFilters}
      {!hideNumericTraits &&
        collectionNumericTraits.map(t => (
          <NumericTraitFilter
            data={t}
            key={t.key}
            range={
              (selectedNumericTraits?.find(nt => nt.name === t.key)?.ranges ||
                [])[0]
            }
            setRange={range => setNumericTrait?.(t.key, range)}
          />
        ))}
    </>
  )
}
