import React, { useEffect, useMemo, useState } from "react"
import { Flex, Input, Text } from "@opensea/ui-kit"
import styled from "styled-components"
import { Panel } from "@/components/layout/Panel"
import {
  FilterBackground,
  FilterDivContainer,
} from "@/components/search/FilterBackground.react"
import { SearchDivider } from "@/components/search/SearchDivider.react"
import { Block } from "@/design-system/Block"
import { Button } from "@/design-system/Button"
import { Select, SelectOption } from "@/design-system/Select"
import { useChains } from "@/hooks/useChains"
import type { ChainIdentifier } from "@/hooks/useChains/types"
import { useTranslate } from "@/hooks/useTranslate"
import { trackApplyPriceFilter } from "@/lib/analytics/events/searchEvents"
import {
  PriceFilterType,
  PriceFilterSymbol,
} from "@/lib/graphql/__generated__/AccountCollectedAssetSearchListQuery.graphql"
import { getEthereumChain, getSolanaChain } from "@/lib/helpers/chainUtils"
import { bn, padEndZeros } from "@/lib/helpers/numberUtils"
import { themeVariant } from "@/styles/styleUtils"

const PRICE_FILTERS = [
  {
    label: "USD",
    value: "USD",
    chain: undefined,
  },
  {
    label: "ETH",
    value: "ETH",
    chain: getEthereumChain(),
  },
  {
    label: "SOL",
    value: "SOL",
    chain: getSolanaChain(),
  },
] as const

type PriceFilterOption = (typeof PRICE_FILTERS)[number]

const usePriceFilters = (defaultChain?: ChainIdentifier) => {
  const { isChainEnabled } = useChains()
  return useMemo(
    () =>
      PRICE_FILTERS.filter(({ chain }) => !chain || isChainEnabled(chain)).sort(
        (a: PriceFilterOption, b: PriceFilterOption) => {
          if (b.chain === defaultChain) {
            return 1
          }
          if (a.chain === defaultChain) {
            return -1
          }
          return 0
        },
      ),
    [isChainEnabled, defaultChain],
  )
}

type Props = {
  defaultChain?: ChainIdentifier
  priceFilter?: PriceFilterType
  setPriceFilter: (priceFilter: PriceFilterType) => unknown
}

export const PriceFilter = ({
  defaultChain,
  priceFilter,
  setPriceFilter,
}: Props) => {
  const t = useTranslate("components")
  const priceFilters = usePriceFilters(defaultChain)
  const [symbol, setSymbol] = useState<
    PriceFilterOption | undefined | SelectOption
  >(
    () =>
      priceFilters.find(f => f.value === priceFilter?.symbol) ??
      priceFilters[0],
  )
  const getSanitizedPrice = (price: string) => {
    return price ? padEndZeros(price, 2) : price
  }

  const [min, setMin] = useState("")
  const [max, setMax] = useState("")

  useEffect(() => {
    setMin(getSanitizedPrice(priceFilter?.min ?? ""))
    setMax(getSanitizedPrice(priceFilter?.max ?? ""))
  }, [priceFilter?.min, priceFilter?.max])

  const equalPriceFilters = (
    a: PriceFilterType | undefined,
    b: PriceFilterType,
  ) => {
    if (a?.symbol !== b.symbol) {
      return false
    }
    if (a.min !== b.min) {
      return false
    }
    if (a.max !== b.max) {
      return false
    }
    return true
  }

  const nonNumericInput =
    (!!min && bn(min).isNaN()) || (!!max && bn(max).isNaN())

  const invalid =
    (!!min && !!max && bn(min).gt(max)) || (bn(min).isEqualTo(0) && !max)

  const apply = () => {
    if (!symbol || invalid || nonNumericInput) {
      return
    }

    const nextPriceFilter: PriceFilterType = {
      symbol: symbol.value as PriceFilterSymbol,
      min: min || undefined,
      max: max || undefined,
    }

    if (equalPriceFilters(priceFilter, nextPriceFilter)) {
      return
    }

    const sanitizedMin = getSanitizedPrice(min)
    if (sanitizedMin && sanitizedMin !== min) {
      setMin(sanitizedMin)
    }

    const sanitizedMax = getSanitizedPrice(max)
    if (sanitizedMax && sanitizedMax !== max) {
      setMax(sanitizedMax)
    }

    trackApplyPriceFilter({ symbol: symbol.value })
    setPriceFilter(nextPriceFilter)
  }

  return (
    <FilterDivContainer>
      <Panel
        bodyClassName="FilterBackground--body"
        headerClassName="FilterBackground--header"
        id="filter-price"
        isHeaderPadded={false}
        mode="start-closed"
        title={t("priceFilter.title", "Price")}
        unmountChildrenOnClose
      >
        <FilterBackground>
          <Flex className="mt-3 items-center px-2 py-0">
            <SelectContainer
              clearable={false}
              options={priceFilters}
              readOnly
              style={{ paddingLeft: "16px", backgroundColor: "transparent" }}
              value={symbol?.value}
              onSelect={setSymbol}
            />
          </Flex>
          <Flex className="mt-3 items-center justify-between px-2 py-0">
            {/* NOTE(@laurafiuza): Setting onBlur and onFocus in order to have the
             placeholder disappear when a user clicks on an input to type. */}
            <InputContainer
              placeholder={t("priceFilter.min.placeholder", "Min")}
              style={{ backgroundColor: "transparent" }}
              value={min}
              onBlur={e =>
                (e.target.placeholder = t("priceFilter.min.placeholder", "Min"))
              }
              onChange={e => setMin(e.target.value)}
              onFocus={e => (e.target.placeholder = "")}
            />
            <Block marginX="8px">
              <Text.Heading asChild size="tiny">
                <div>{t("priceFilter.to", "to")}</div>
              </Text.Heading>
            </Block>
            {/* NOTE(@laurafiuza): Setting onBlur and onFocus in order to have the
             placeholder disappear when a user clicks on an input to type. */}
            <InputContainer
              placeholder={t("priceFilter.max.placeholder", "Max")}
              style={{ backgroundColor: "transparent" }}
              value={max}
              onBlur={e =>
                (e.target.placeholder = t("priceFilter.max.placeholder", "Max"))
              }
              onChange={e => setMax(e.target.value)}
              onFocus={e => (e.target.placeholder = "")}
            />
          </Flex>
          {invalid ? (
            <Block marginTop="16px" paddingLeft="12px">
              <Text color="error" size="small" weight="semibold">
                {t(
                  "priceFilter.validation.minLessThanMax",
                  "Minimum must be less than maximum",
                )}
              </Text>
            </Block>
          ) : null}
          {nonNumericInput ? (
            <Block marginTop="16px" paddingLeft="12px">
              <Text color="error" size="small" weight="semibold">
                {t(
                  "priceFilter.validation.numericInput",
                  "Numeric inputs only",
                )}
              </Text>
            </Block>
          ) : null}
          <Button
            className="my-4 w-full"
            disabled={!symbol || (!min && !max) || invalid || nonNumericInput}
            onClick={apply}
          >
            {t("priceFilter.apply", "Apply")}
          </Button>
          <SearchDivider />
        </FilterBackground>
      </Panel>
    </FilterDivContainer>
  )
}

const SelectContainer = styled(Select)`
  align-items: center;
  background: ${props => props.theme.colors.base1};
  width: 100%;
  height: 48px;
  ${props =>
    themeVariant({
      variants: {
        light: { border: `1px solid ${props.theme.colors.fog}` },
        dark: { border: `1px solid  ${props.theme.colors.ash}` },
      },
    })}
  padding-left: 12px;

  input {
    font-weight: 600;
    font-size: 16px;
  }
  i {
    color: ${props => props.theme.colors.text.primary};
  }
`

const InputContainer = styled(Input)`
  align-items: center;
  background: ${props => props.theme.colors.base1};
  min-width: 102px;
  width: 100%;
  height: 48px;
  ${props =>
    themeVariant({
      variants: {
        light: { border: `1px solid ${props.theme.colors.fog}` },
        dark: { border: `1px solid  ${props.theme.colors.ash}` },
      },
    })}
  border-radius: ${props => props.theme.borderRadius.button};
  input {
    text-align: center;
    font-weight: 400;
    font-size: 16px;
  }
`
