import { useTranslate } from "@/hooks/useTranslate"
import { EventType } from "@/lib/graphql/__generated__/AssetType.graphql"
import { Item } from "../../FeaturedFilter.react"

export const useEventFilters = (): Item<EventType>[] => {
  const t = useTranslate("components")
  return [
    {
      filter: "AUCTION_SUCCESSFUL",
      label: t("search.eventFilters.sales", "Sales"),
    },
    {
      filter: "SWAP_ACCEPTED",
      label: t("search.eventFilters.dealsAccepted", "Deals accepted"),
    },
    {
      filter: "AUCTION_CREATED",
      label: t("search.eventFilters.listings", "Listings"),
    },
    {
      filter: "OFFER_ENTERED",
      label: t("search.eventFilters.offers", "Offers"),
    },
    {
      filter: "COLLECTION_OFFER",
      label: t("search.eventFilters.collectionOffers", "Collection offers"),
    },
    {
      filter: "SWAP_CREATED",
      label: t("search.eventFilters.dealsCreated", "Deal offers"),
    },
    {
      filter: "ASSET_TRANSFER",
      label: t("search.eventFilters.transfers", "Transfers"),
    },
  ]
}
