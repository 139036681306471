import React, { ComponentProps } from "react"
import { Container, classNames } from "@opensea/ui-kit"
import { AnimatePresence, motion } from "framer-motion"
import { transparentize } from "polished"
import { useUpdateEffect } from "react-use"
import styled from "styled-components"
import { variant } from "styled-system"
import { Z_INDEX } from "@/constants/zIndex"
import {
  TOAST_BOTTOM_OFFSET_DEFAULT,
  TOAST_BOTTOM_OFFSET_DEFAULT_PADDING_DESKTOP,
} from "@/containers/ToastProvider.react"
import { Block, BlockProps } from "@/design-system/Block"
import { useToasts } from "@/hooks/useToasts"

export const BOTTOM_ACTION_BAR_HEIGHT = 72
export const BOTTOM_ACTION_BAR_HEIGHT_PX = `${BOTTOM_ACTION_BAR_HEIGHT}px`

const BOTTOM_ACTION_BAR_TOP_MARGIN = TOAST_BOTTOM_OFFSET_DEFAULT_PADDING_DESKTOP
const TOAST_OFFSET = BOTTOM_ACTION_BAR_HEIGHT + BOTTOM_ACTION_BAR_TOP_MARGIN
export const BOTTOM_ACTION_BAR_TOAST_OFFSET = {
  mobile: TOAST_OFFSET,
  desktop: TOAST_OFFSET,
}

type BottomActionBarVariant = "default" | "translucent"

type Props = {
  isOpen: boolean
  children: React.ReactNode
  variant?: BottomActionBarVariant
  showBorder?: boolean
} & ComponentProps<typeof Container> &
  Pick<BlockProps, "backgroundColor">

export const BottomActionBar = ({
  isOpen,
  children,
  variant = "default",
  backgroundColor,
  showBorder = true,
  ...containerProps
}: Props) => {
  const { setToastYOffset } = useToasts()

  useUpdateEffect(() => {
    if (isOpen) {
      setToastYOffset(BOTTOM_ACTION_BAR_TOAST_OFFSET)
    } else {
      setToastYOffset(TOAST_BOTTOM_OFFSET_DEFAULT)
    }
    return () => {
      setToastYOffset(TOAST_BOTTOM_OFFSET_DEFAULT)
    }
  }, [isOpen, setToastYOffset])

  return (
    <AnimatePresence>
      {isOpen && (
        <StyledFooter
          animate={{ y: 0 }}
          className={classNames(
            "bg-base-1",
            showBorder && "border-t border-level-1",
          )}
          exit={{ y: "100%" }}
          initial={{ y: "100%" }}
          transition={{ ease: "easeInOut", duration: 0.25 }}
          variant={variant}
        >
          {/*
      To support background colors spanning the entire width of the footer on
      XXL screen sizes, the background color is applied to a wrapper block since
      it needs to be applied on top of the base1 background of StyledFooter
      where they usually have opacity, e.g. gray1.
      */}
          <Block backgroundColor={backgroundColor} className="w-full">
            <FooterContent {...containerProps}>{children}</FooterContent>
          </Block>
        </StyledFooter>
      )}
    </AnimatePresence>
  )
}

export const StyledFooter = styled(motion.footer)<{
  variant: BottomActionBarVariant
}>`
  align-items: center;

  bottom: 0;
  display: flex;
  left: 0;
  right: 0;
  position: fixed;
  transform: translateY(100%);
  width: 100%;
  justify-content: center;
  z-index: ${Z_INDEX.MANAGER_BAR};

  ${props =>
    variant({
      variants: {
        translucent: {
          background: transparentize(0.2, props.theme.colors.base1),
          backdropFilter: "blur(50px)",
        },
      },
    })}
`

const FooterContent = styled(Container)`
  display: flex;
  padding-top: 12px;
  padding-bottom: 12px;
  align-items: center;
  width: 100%;
`
