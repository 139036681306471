import React, { CSSProperties } from "react"
import { CenterAligned, Text } from "@opensea/ui-kit"
import { Block } from "@/design-system/Block"
import { Button } from "@/design-system/Button"
import { useTranslate } from "@/hooks/useTranslate"

type ErrorFetchingDataProps = {
  resetError: () => void
  height?: CSSProperties["height"]
}

export const ErrorFetchingData = ({
  resetError,
  height,
}: ErrorFetchingDataProps) => {
  const t = useTranslate("collection")
  return (
    <CenterAligned className="p-8" style={{ height }}>
      <Block className="text-center text-secondary" maxWidth="408px">
        <Text.Body asChild size="small" weight="semibold">
          <div>{t("errorFetchingData.title", "Something went wrong")}</div>
        </Text.Body>
        <Text.Body asChild size="small">
          <div>
            {t(
              "errorFetchingData.body",
              "There has been an issue fetching this data. Try to refresh.",
            )}
          </div>
        </Text.Body>
        <Button
          className="mt-6"
          icon="refresh"
          size="small"
          variant="secondary"
          onClick={resetError}
        >
          {t("errorFetchingData.refresh", "Refresh")}
        </Button>
      </Block>
    </CenterAligned>
  )
}
