import React, { ComponentProps, useEffect, useMemo, useState } from "react"
import { Text, Skeleton } from "@opensea/ui-kit"
import { sortBy } from "lodash"
import { useRefetchableFragment } from "react-relay"
import { useDebounce } from "use-debounce"
import { SsrSuspense } from "@/components/common/SsrSuspense.react"
import { Panel } from "@/components/layout/Panel"
import {
  FilterBackground,
  FilterDivContainer,
} from "@/components/search/FilterBackground.react"
import { FilterSearchInput } from "@/components/search/FilterSearchInput.react"
import { SearchOptions } from "@/components/search/SearchOptions.react"
import { Block } from "@/design-system/Block"
import { useTranslate } from "@/hooks/useTranslate"
import type { PaymentFilter_data$key } from "@/lib/graphql/__generated__/PaymentFilter_data.graphql"
import { PaymentFilterRefetchQuery } from "@/lib/graphql/__generated__/PaymentFilterRefetchQuery.graphql"
import { getNodes, graphql } from "@/lib/graphql/graphql"
import { OrderedSet } from "@/lib/helpers/array"

type Props = {
  activeSymbols: ReadonlyArray<string>
  data: PaymentFilter_data$key | null
  setPaymentAssets: (values?: ReadonlyArray<string>) => unknown
  isCollectionPage?: boolean
}

const DEBOUNCE_DEFAULT = 500

const PaymentFilterContent = ({
  activeSymbols,
  data: dataKey,
  setPaymentAssets,
  isCollectionPage,
  query,
}: Props & {
  query: string
}) => {
  const [data, refetch] = useRefetchableFragment<
    PaymentFilterRefetchQuery,
    PaymentFilter_data$key
  >(
    graphql`
      fragment PaymentFilter_data on Query
      @argumentDefinitions(
        collection: { type: "CollectionSlug" }
        count: { type: "Int", defaultValue: 10 }
        query: { type: "String" }
        after: { type: "String" }
      )
      @refetchable(queryName: "PaymentFilterRefetchQuery") {
        paymentAssets(first: $count, symbolIcontains: $query, after: $after)
          @connection(key: "PaymentFilter_paymentAssets") {
          edges {
            node {
              symbol
            }
          }
        }
        PaymentFilter_collection: collection(collection: $collection) {
          paymentAssets {
            symbol
          }
        }
      }
    `,
    dataKey,
  )

  useEffect(() => {
    if (!isCollectionPage) {
      refetch({
        query,
        count: 10,
      })
    }
  }, [query, isCollectionPage, refetch])

  const collection = data?.PaymentFilter_collection
  const rawPaymentAssets = collection
    ? collection.paymentAssets
    : getNodes(data?.paymentAssets)

  const paymentAssets = useMemo(() => {
    return new OrderedSet(paymentAsset => paymentAsset.symbol, rawPaymentAssets)
      .elements
  }, [rawPaymentAssets])

  const sortedSymbols = useMemo(() => {
    const bubbleWethAndEthToTheTop = [
      (symbol: string) => symbol && !["WETH", "ETH"].includes(symbol),
    ]
    return sortBy(
      paymentAssets.map(paymentAsset => paymentAsset.symbol).sort(),
      bubbleWethAndEthToTheTop,
    )
  }, [paymentAssets])

  const items = useMemo(() => {
    return sortedSymbols.map(symbol => {
      // Shows a checkmark next to the ticker if it's selected.
      const checked = activeSymbols.includes(symbol)

      return {
        id: symbol,
        isSelected: checked,
        label: (
          <Text asChild>
            <div>{symbol}</div>
          </Text>
        ),
        onChange: () => {
          const newSymbols = checked
            ? // If it's already checked, remove it.
              activeSymbols.filter((s: string) => s !== symbol)
            : // If it's not already checked, add it.
              [...activeSymbols, symbol]

          setPaymentAssets(newSymbols.length ? newSymbols : undefined)
        },
      }
    })
  }, [sortedSymbols, activeSymbols, setPaymentAssets])

  return (
    <>
      {/* The search divider is removed here in favor of the always-on hairline
      that divides the properties from the non-properties (see
      ./SearchProperties.react.tsx). It's only present in this component because
      this is always the last non-property search panel above the first property
      search panel. */}
      <SearchOptions
        items={items}
        name="payment-filter"
        type="checkbox"
        withSearchDivider={false}
      />
    </>
  )
}

function PaymentFilterSearchLoader() {
  return (
    <Skeleton>
      <StyledSkeletonRow>
        <Skeleton.Block className="h-12 w-full" />
      </StyledSkeletonRow>
      <StyledSkeletonRow>
        <Skeleton.Block className="h-12 w-full" />
      </StyledSkeletonRow>
      <StyledSkeletonRow>
        <Skeleton.Block className="h-12 w-full" />
      </StyledSkeletonRow>
    </Skeleton>
  )
}

const StyledSkeletonRow = ({
  children,
  ...props
}: ComponentProps<typeof Skeleton.Row>) => (
  <Skeleton.Row className="rounded-xl px-2.5 py-0" {...props}>
    {children}
  </Skeleton.Row>
)

export const PaymentFilter = (props: Props) => {
  const t = useTranslate("components")
  const [query, setQuery] = useState("")
  const [debouncedQuery] = useDebounce(query, DEBOUNCE_DEFAULT)

  return (
    <FilterDivContainer>
      <Panel
        bodyClassName="FilterBackground--body FilterBackground--bottom-padding"
        headerClassName="FilterBackground--header"
        id="filter-payment"
        isHeaderPadded={false}
        mode="start-closed"
        title={t("paymentFilter.title", "Currency")}
        unmountChildrenOnClose
      >
        <FilterBackground>
          {!props.isCollectionPage && (
            <Block marginY="8px" padding="0 8px">
              <FilterSearchInput
                placeholder={t("paymentFilter.placeholder", "Search")}
                value={query}
                onChange={e => setQuery(e.target.value)}
              />
            </Block>
          )}
          <SsrSuspense fallback={<PaymentFilterSearchLoader />}>
            <PaymentFilterContent {...props} query={debouncedQuery} />
          </SsrSuspense>
        </FilterBackground>
      </Panel>
    </FilterDivContainer>
  )
}
