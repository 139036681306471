/**
 * @generated SignedSource<<7184ac4dd50d4c6d773e0faffcfdaea6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type PortfolioTableListingTooltipQuery$variables = {
  order: string;
};
export type PortfolioTableListingTooltipQuery$data = {
  readonly order: {
    readonly closedAt: string | null;
    readonly perUnitPriceType: {
      readonly eth: string;
      readonly usd: string;
    };
  };
};
export type PortfolioTableListingTooltipQuery = {
  response: PortfolioTableListingTooltipQuery$data;
  variables: PortfolioTableListingTooltipQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "order"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "order",
    "variableName": "order"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "PriceType",
  "kind": "LinkedField",
  "name": "perUnitPriceType",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "usd",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "eth",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "closedAt",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PortfolioTableListingTooltipQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "OrderV2Type",
        "kind": "LinkedField",
        "name": "order",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PortfolioTableListingTooltipQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "OrderV2Type",
        "kind": "LinkedField",
        "name": "order",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "cfde7f10b039b6c904e0abd065ad89b8",
    "id": null,
    "metadata": {},
    "name": "PortfolioTableListingTooltipQuery",
    "operationKind": "query",
    "text": "query PortfolioTableListingTooltipQuery(\n  $order: OrderRelayID!\n) {\n  order(order: $order) {\n    perUnitPriceType {\n      usd\n      eth\n    }\n    closedAt\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "5a8701b43a98505de48c890a3adc269e";

export default node;
