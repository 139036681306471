/**
 * @generated SignedSource<<656b4ecfc55da3002d60030c29a4c622>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProfileCollectionFilterItem_collection$data = {
  readonly collectionAssetOwner: {
    readonly expectedValue: {
      readonly symbol: string;
      readonly unit: string;
    } | null;
    readonly listedQuantity: string;
    readonly ownedQuantity: string;
  } | null;
  readonly isVerified: boolean;
  readonly logo: string | null;
  readonly name: string;
  readonly slug: string;
  readonly statsV2: {
    readonly floorPrice: {
      readonly symbol: string;
      readonly unit: string;
    } | null;
  };
  readonly " $fragmentType": "ProfileCollectionFilterItem_collection";
};
export type ProfileCollectionFilterItem_collection$key = {
  readonly " $data"?: ProfileCollectionFilterItem_collection$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProfileCollectionFilterItem_collection">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "unit",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "symbol",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "owner"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProfileCollectionFilterItem_collection",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "logo",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isVerified",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "identity",
          "variableName": "owner"
        }
      ],
      "concreteType": "CollectionAssetOwnerType",
      "kind": "LinkedField",
      "name": "collectionAssetOwner",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "listedQuantity",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "ownedQuantity",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PriceType",
          "kind": "LinkedField",
          "name": "expectedValue",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CollectionStatsV2Type",
      "kind": "LinkedField",
      "name": "statsV2",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "PriceType",
          "kind": "LinkedField",
          "name": "floorPrice",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "CollectionType",
  "abstractKey": null
};
})();

(node as any).hash = "4f4d3c5e59ce5edd1cea8b382d134561";

export default node;
