import { useCallback } from "react"
import type { Item } from "@/components/search/featured-filter"
import { StatusIndicatorType } from "@/features/account/components/PortfolioTable/components/cells/PortfolioTableListingCell.react"
import { Search } from "@/hooks/useSearch"
import { useTranslate } from "@/hooks/useTranslate"
import { SearchToggle } from "@/lib/graphql/__generated__/AccountCollectedAssetSearchListQuery.graphql"
import { flatMap } from "@/lib/helpers/array"
import { snakeCaseToSentenceCase } from "@/lib/helpers/stringUtils"

export const getFilterCount = (
  state: Search,
  { includeCollectionFilter }: { includeCollectionFilter?: boolean } = {
    includeCollectionFilter: true,
  },
): number =>
  [
    ...(state.query ? [state.query] : []),
    ...((includeCollectionFilter && state.collections) || []),
    ...(state.categories || []),
    ...(state.chains || []),
    ...(state.stringTraits
      ? flatMap(state.stringTraits, trait => trait.values)
      : []),
    ...(state.numericTraits || []),
    ...(state.toggles || []),
    ...(state.paymentAssets || []),
    ...(state.priceFilter ? [true] : []),
    ...(state.rarityFilter ? [true] : []),
    ...(state.isAutoHidden !== undefined ? [state.isAutoHidden] : []),
    ...(state.owner !== undefined ? [state.owner] : []),
  ].length

export const useCollectionPageStatusItems = (): Item<SearchToggle>[] => {
  const t = useTranslate("components")

  const defaultItems: Item<SearchToggle>[] = [
    {
      filter: "BUY_NOW",
      label: t("assetSearchFilter.status.buyNow", "Buy Now"),
    },
    {
      filter: "ON_AUCTION",
      label: t("assetSearchFilter.status.onAuction", "On auction"),
    },
  ]

  return defaultItems
}

export const useDefaultStatusItems = (): Item<SearchToggle>[] => {
  const t = useTranslate("components")
  return [
    {
      filter: "IS_LISTED",
      label: t("assetSearchFilter.status.listed", "Listed"),
    },
    {
      filter: "ON_AUCTION",
      label: t("assetSearchFilter.status.onAuction", "On auction"),
    },
    {
      filter: "IS_NEW",
      label: t("assetSearchFilter.status.new", "New"),
    },
    {
      filter: "HAS_OFFERS",
      label: t("assetSearchFilter.status.hasOffers", "Has offers"),
    },
  ]
}

export const useGetStatusItemLabel = (): ((status: SearchToggle) => string) => {
  const defaultStatusItems = useDefaultStatusItems()
  const collectionPageStatusItems = useCollectionPageStatusItems()
  return useCallback(
    (status: SearchToggle) => {
      const statusItem =
        defaultStatusItems.find(statusItem => statusItem.filter === status) ??
        collectionPageStatusItems.find(
          statusItem => statusItem.filter === status,
        )

      return statusItem?.label ?? snakeCaseToSentenceCase(status)
    },
    [collectionPageStatusItems, defaultStatusItems],
  )
}

export const getStatusVariant: (
  status: SearchToggle,
) => StatusIndicatorType | undefined = (status: SearchToggle) => {
  switch (status) {
    case "IS_LISTED":
      return "listed"
    default: {
      return undefined
    }
  }
}
