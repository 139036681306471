import { AssetCardVariant } from "@/components/search/assets/AssetSearchView"
import { Search, SearchType } from "@/hooks/useSearch"
import { getTrackingFn } from "../utils"

export const trackCloseSidebarFilter = getTrackingFn("close sidebar filter")
export const trackApplyPriceFilter = getTrackingFn<{ symbol: string }>(
  "apply price filter",
)
export const trackApplyRarityFilter = getTrackingFn<{
  min?: string
  max?: string
}>("apply rarity filter")

type SearchEventParams = {
  path: string
  queryString: string
  type: SearchType
  // TODO: Make this required when implemented for AssetSearch, OfferSearch and ListingSearch
  hits?: number
  pageIndex: number
  itemDisplayVariant: AssetCardVariant | "unset" | undefined
} & Search
export const trackSearch = getTrackingFn<SearchEventParams>("search")
